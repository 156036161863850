import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./LivePage.css";
import { parseNostrLink } from "@snort/system";
import { useParams } from "react-router-dom";
import { LiveVideoPlayer } from "Element/LiveVideoPlayer";
import { findTag, unwrap } from "SnortUtils";
import PageSpinner from "Element/PageSpinner";
import { LiveChat } from "Element/LiveChat";
import useEventFeed from "Feed/EventFeed";
import ProfilePreview from "Element/ProfilePreview";
import AsyncButton from "Element/AsyncButton";
import { FormattedMessage } from "react-intl";
import Icon from "Icons/Icon";
export function LivePage() {
    const params = useParams();
    const link = parseNostrLink(unwrap(params.id));
    const thisEvent = useEventFeed(link);
    if (!thisEvent.data) {
        return _jsx(PageSpinner, {});
    }
    return (_jsxs("div", { className: "live-page main-content", children: [_jsxs("div", { children: [_jsx(LiveVideoPlayer, { stream: unwrap(findTag(thisEvent.data, "streaming")), autoPlay: true }), _jsxs("div", { className: "flex", children: [_jsxs("div", { className: "f-grow", children: [_jsx("h1", { children: findTag(thisEvent.data, "title") }), _jsx("p", { children: findTag(thisEvent.data, "summary") }), _jsx("div", { children: thisEvent.data?.tags
                                            .filter(a => a[0] === "t")
                                            .map(a => a[1])
                                            .map(a => (_jsx("div", { className: "pill", children: a }, a))) })] }), _jsx("div", { children: _jsx(ProfilePreview, { pubkey: thisEvent.data.pubkey, className: "g10", options: {
                                        about: false,
                                    }, actions: _jsx("div", { className: "flex", children: _jsxs(AsyncButton, { onClick: () => { }, children: [_jsx(Icon, { name: "zap", size: 16, className: "mr5" }), _jsx(FormattedMessage, { id: 'fBI91o', defaultMessage: 'Zap' })] }) }) }) })] })] }), _jsx(LiveChat, { ev: thisEvent.data, link: link })] }));
}
