var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _SemisolDevApi_instances, _SemisolDevApi_url, _SemisolDevApi_json;
export class SemisolDevApiError extends Error {
    constructor(message, body, status) {
        super(message);
        this.body = body;
        this.statusCode = status;
    }
}
export default class SemisolDevApi {
    constructor() {
        _SemisolDevApi_instances.add(this);
        _SemisolDevApi_url.set(this, "https://api.semisol.dev");
    }
    async sugguestedFollows(pubkey, follows) {
        return await __classPrivateFieldGet(this, _SemisolDevApi_instances, "m", _SemisolDevApi_json).call(this, "POST", "/nosgraph/v1/recommend", {
            pubkey,
            exclude: [],
            following: follows,
        });
    }
}
_SemisolDevApi_url = new WeakMap(), _SemisolDevApi_instances = new WeakSet(), _SemisolDevApi_json = async function _SemisolDevApi_json(method, path, body) {
    const url = `${__classPrivateFieldGet(this, _SemisolDevApi_url, "f")}${path}`;
    const res = await fetch(url, {
        method: method ?? "GET",
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            ...(body ? { "content-type": "application/json" } : {}),
        },
    });
    if (res.ok) {
        return (await res.json());
    }
    else {
        throw new SemisolDevApiError(`Failed to load content from ${url}`, await res.text(), res.status);
    }
};
