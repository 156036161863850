import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Timeline.css";
import { FormattedMessage } from "react-intl";
import { useCallback, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { EventKind, parseZap } from "@snort/system";
import Icon from "Icons/Icon";
import { dedupeByPubkey, findTag, tagFilterOfTextRepost } from "SnortUtils";
import ProfileImage from "Element/ProfileImage";
import useTimelineFeed from "Feed/TimelineFeed";
import Zap from "Element/Zap";
import Note from "Element/Note";
import NoteReaction from "Element/NoteReaction";
import useModeration from "Hooks/useModeration";
import ProfilePreview from "Element/ProfilePreview";
import { UserCache } from "Cache";
/**
 * A list of notes by pubkeys
 */
const Timeline = (props) => {
    const feedOptions = useMemo(() => {
        return {
            method: props.method,
            window: props.window,
            now: props.now,
        };
    }, [props]);
    const feed = useTimelineFeed(props.subject, feedOptions);
    const { muted, isMuted } = useModeration();
    const { ref, inView } = useInView();
    const filterPosts = useCallback((nts) => {
        const a = [...nts];
        props.noSort || a.sort((a, b) => b.created_at - a.created_at);
        return a
            ?.filter(a => (props.postsOnly ? !a.tags.some(b => b[0] === "e") : true))
            .filter(a => props.ignoreModeration || !isMuted(a.pubkey));
    }, [props.postsOnly, muted, props.ignoreModeration]);
    const mainFeed = useMemo(() => {
        return filterPosts(feed.main ?? []);
    }, [feed, filterPosts]);
    const latestFeed = useMemo(() => {
        return filterPosts(feed.latest ?? []).filter(a => !mainFeed.some(b => b.id === a.id));
    }, [feed, filterPosts]);
    const relatedFeed = useCallback((id) => {
        return (feed.related ?? []).filter(a => findTag(a, "e") === id);
    }, [feed.related]);
    const findRelated = useCallback((id) => {
        if (!id)
            return undefined;
        return (feed.related ?? []).find(a => a.id === id);
    }, [feed.related]);
    const latestAuthors = useMemo(() => {
        return dedupeByPubkey(latestFeed).map(e => e.pubkey);
    }, [latestFeed]);
    function eventElement(e) {
        switch (e.kind) {
            case EventKind.SetMetadata: {
                return _jsx(ProfilePreview, { actions: _jsx(_Fragment, {}), pubkey: e.pubkey, className: "card" });
            }
            case EventKind.Polls:
            case EventKind.TextNote: {
                const eRef = e.tags.find(tagFilterOfTextRepost(e))?.at(1);
                if (eRef) {
                    return _jsx(NoteReaction, { data: e, root: findRelated(eRef) }, e.id);
                }
                return (_jsx(Note, { data: e, related: relatedFeed(e.id), ignoreModeration: props.ignoreModeration, depth: 0 }, e.id));
            }
            case EventKind.ZapReceipt: {
                const zap = parseZap(e, UserCache);
                return zap.event ? null : _jsx(Zap, { zap: zap }, e.id);
            }
            case EventKind.Reaction:
            case EventKind.Repost: {
                const eRef = findTag(e, "e");
                return _jsx(NoteReaction, { data: e, root: findRelated(eRef) }, e.id);
            }
        }
    }
    function onShowLatest(scrollToTop = false) {
        feed.showLatest();
        if (scrollToTop) {
            window.scrollTo(0, 0);
        }
    }
    return (_jsxs(_Fragment, { children: [latestFeed.length > 0 && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "card latest-notes pointer", onClick: () => onShowLatest(), ref: ref, children: [latestAuthors.slice(0, 3).map(p => {
                                return _jsx(ProfileImage, { pubkey: p, showUsername: false, link: "" });
                            }), _jsx(FormattedMessage, { id: '3t3kok', defaultMessage: '{n} new {n, plural, =1 {note} other {notes}}', values: { n: latestFeed.length } }), _jsx(Icon, { name: "arrowUp" })] }), !inView && (_jsxs("div", { className: "card latest-notes latest-notes-fixed pointer fade-in", onClick: () => onShowLatest(true), children: [latestAuthors.slice(0, 3).map(p => {
                                return _jsx(ProfileImage, { pubkey: p, showUsername: false, link: "" });
                            }), _jsx(FormattedMessage, { id: '3t3kok', defaultMessage: '{n} new {n, plural, =1 {note} other {notes}}', values: { n: latestFeed.length } }), _jsx(Icon, { name: "arrowUp" })] }))] })), mainFeed.map(eventElement), (props.loadMore === undefined || props.loadMore === true) && (_jsx("div", { className: "flex f-center", children: _jsx("button", { type: "button", onClick: () => feed.loadMore(), children: _jsx(FormattedMessage, { id: '00LcfG', defaultMessage: 'Load more' }) }) }))] }));
};
export default Timeline;
