import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./WalletSettings.css";
import LndLogo from "lnd-logo.png";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import BlueWallet from "Icons/BlueWallet";
import ConnectLNC from "Pages/settings/wallet/LNC";
import ConnectLNDHub from "Pages/settings/wallet/LNDHub";
import ConnectNostrWallet from "Pages/settings/wallet/NWC";
import ConnectCashu from "Pages/settings/wallet/Cashu";
import NostrIcon from "Icons/Nostrich";
const WalletSettings = () => {
    const navigate = useNavigate();
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'cg1VJ2', defaultMessage: 'Connect Wallet' }) }), _jsxs("div", { className: "wallet-grid", children: [_jsxs("div", { className: "card", onClick: () => navigate("/settings/wallet/lnc"), children: [_jsx("img", { src: LndLogo, width: 100 }), _jsx("h3", { className: "f-end", children: "LND with LNC" })] }), _jsxs("div", { className: "card", onClick: () => navigate("/settings/wallet/lndhub"), children: [_jsx(BlueWallet, { width: 100, height: 100 }), _jsx("h3", { className: "f-end", children: "LNDHub" })] }), _jsxs("div", { className: "card", onClick: () => navigate("/settings/wallet/nwc"), children: [_jsx(NostrIcon, { width: 100, height: 100 }), _jsx("h3", { className: "f-end", children: "Nostr Wallet Connect" })] })] })] }));
};
export default WalletSettings;
export const WalletSettingsRoutes = [
    {
        path: "/settings/wallet",
        element: _jsx(WalletSettings, {}),
    },
    {
        path: "/settings/wallet/lnc",
        element: _jsx(ConnectLNC, {}),
    },
    {
        path: "/settings/wallet/lndhub",
        element: _jsx(ConnectLNDHub, {}),
    },
    {
        path: "/settings/wallet/nwc",
        element: _jsx(ConnectNostrWallet, {}),
    },
    {
        path: "/settings/wallet/cashu",
        element: _jsx(ConnectCashu, {}),
    },
];
