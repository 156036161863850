import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MixCloudRegex } from "Const";
import useLogin from "Hooks/useLogin";
const MixCloudEmbed = ({ link }) => {
    const feedPath = (MixCloudRegex.test(link) && RegExp.$1) + "%2F" + (MixCloudRegex.test(link) && RegExp.$2);
    const lightTheme = useLogin().preferences.theme === "light";
    const lightParams = lightTheme ? "light=1" : "light=0";
    return (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx("iframe", { title: "SoundCloud player", width: "100%", height: "120", frameBorder: "0", src: `https://www.mixcloud.com/widget/iframe/?hide_cover=1&${lightParams}&feed=%2F${feedPath}%2F` })] }));
};
export default MixCloudEmbed;
