import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Copy.css";
import Icon from "Icons/Icon";
import { useCopy } from "useCopy";
export default function Copy({ text, maxSize = 32, className }) {
    const { copy, copied } = useCopy();
    const sliceLength = maxSize / 2;
    const trimmed = text.length > maxSize ? `${text.slice(0, sliceLength)}...${text.slice(-sliceLength)}` : text;
    return (_jsxs("div", { className: `flex flex-row copy ${className}`, onClick: () => copy(text), children: [_jsx("span", { className: "body", children: trimmed }), _jsx("span", { className: "icon", style: { color: copied ? "var(--success)" : "var(--highlight)" }, children: copied ? _jsx(Icon, { name: "check", size: 14 }) : _jsx(Icon, { name: "copy", size: 14 }) })] }));
}
