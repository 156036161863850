import { prToWalletInvoice, WalletError, WalletErrorCode, WalletInvoiceState, WalletKind, } from "Wallet";
import { barrierQueue, processWorkQueue } from "@snort/shared";
const WebLNQueue = [];
processWorkQueue(WebLNQueue);
/**
 * Adds a wallet config for WebLN if detected
 */
export function setupWebLNWalletConfig(store) {
    const wallets = store.list();
    if (window.webln && !wallets.some(a => a.kind === WalletKind.WebLN)) {
        const newConfig = {
            id: "webln",
            kind: WalletKind.WebLN,
            active: wallets.length === 0,
            info: {
                alias: "WebLN",
            },
        };
        store.add(newConfig);
    }
}
export class WebLNWallet {
    isReady() {
        return window.webln !== undefined && window.webln !== null;
    }
    canAutoLogin() {
        return true;
    }
    async getInfo() {
        await this.login();
        if (this.isReady()) {
            const rsp = await barrierQueue(WebLNQueue, async () => await window.webln?.getInfo());
            if (rsp) {
                return {
                    nodePubKey: rsp.node.pubkey,
                    alias: rsp.node.alias,
                };
            }
            else {
                throw new WalletError(WalletErrorCode.GeneralError, "Could not load wallet info");
            }
        }
        throw new WalletError(WalletErrorCode.GeneralError, "WebLN not available");
    }
    async login() {
        if (window.webln) {
            await window.webln.enable();
        }
        return true;
    }
    close() {
        return Promise.resolve(true);
    }
    getBalance() {
        return Promise.resolve(0);
    }
    async createInvoice(req) {
        await this.login();
        if (this.isReady()) {
            const rsp = await barrierQueue(WebLNQueue, async () => await window.webln?.makeInvoice({
                amount: req.amount,
                defaultMemo: req.memo,
            }));
            if (rsp) {
                const invoice = prToWalletInvoice(rsp.paymentRequest);
                if (!invoice) {
                    throw new WalletError(WalletErrorCode.InvalidInvoice, "Could not parse invoice");
                }
                return invoice;
            }
        }
        throw new WalletError(WalletErrorCode.GeneralError, "WebLN not available");
    }
    async payInvoice(pr) {
        await this.login();
        if (this.isReady()) {
            const invoice = prToWalletInvoice(pr);
            if (!invoice) {
                throw new WalletError(WalletErrorCode.InvalidInvoice, "Could not parse invoice");
            }
            const rsp = await barrierQueue(WebLNQueue, async () => await window.webln?.sendPayment(pr));
            if (rsp) {
                invoice.state = WalletInvoiceState.Paid;
                invoice.preimage = rsp.preimage;
                return invoice;
            }
            else {
                invoice.state = WalletInvoiceState.Failed;
                return invoice;
            }
        }
        throw new WalletError(WalletErrorCode.GeneralError, "WebLN not available");
    }
    getInvoices() {
        return Promise.resolve([]);
    }
}
