var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _WalletStore_instances, _WalletStore_configs, _WalletStore_instance, _WalletStore_activateWallet;
import { useEffect, useSyncExternalStore } from "react";
import { ExternalStore, decodeInvoice } from "@snort/shared";
import { unwrap } from "SnortUtils";
import LNDHubWallet from "./LNDHub";
import { NostrConnectWallet } from "./NostrWalletConnect";
import { setupWebLNWalletConfig, WebLNWallet } from "./WebLN";
export var WalletKind;
(function (WalletKind) {
    WalletKind[WalletKind["LNDHub"] = 1] = "LNDHub";
    WalletKind[WalletKind["LNC"] = 2] = "LNC";
    WalletKind[WalletKind["WebLN"] = 3] = "WebLN";
    WalletKind[WalletKind["NWC"] = 4] = "NWC";
    WalletKind[WalletKind["Cashu"] = 5] = "Cashu";
})(WalletKind || (WalletKind = {}));
export var WalletErrorCode;
(function (WalletErrorCode) {
    WalletErrorCode[WalletErrorCode["BadAuth"] = 1] = "BadAuth";
    WalletErrorCode[WalletErrorCode["NotEnoughBalance"] = 2] = "NotEnoughBalance";
    WalletErrorCode[WalletErrorCode["BadPartner"] = 3] = "BadPartner";
    WalletErrorCode[WalletErrorCode["InvalidInvoice"] = 4] = "InvalidInvoice";
    WalletErrorCode[WalletErrorCode["RouteNotFound"] = 5] = "RouteNotFound";
    WalletErrorCode[WalletErrorCode["GeneralError"] = 6] = "GeneralError";
    WalletErrorCode[WalletErrorCode["NodeFailure"] = 7] = "NodeFailure";
})(WalletErrorCode || (WalletErrorCode = {}));
export class WalletError extends Error {
    constructor(c, msg) {
        super(msg);
        this.code = c;
    }
}
export const UnknownWalletError = {
    code: WalletErrorCode.GeneralError,
    message: "Unknown error",
};
export var WalletInvoiceState;
(function (WalletInvoiceState) {
    WalletInvoiceState[WalletInvoiceState["Pending"] = 0] = "Pending";
    WalletInvoiceState[WalletInvoiceState["Paid"] = 1] = "Paid";
    WalletInvoiceState[WalletInvoiceState["Expired"] = 2] = "Expired";
    WalletInvoiceState[WalletInvoiceState["Failed"] = 3] = "Failed";
})(WalletInvoiceState || (WalletInvoiceState = {}));
export function prToWalletInvoice(pr) {
    const parsedInvoice = decodeInvoice(pr);
    if (parsedInvoice) {
        return {
            amount: parsedInvoice.amount ?? 0,
            memo: parsedInvoice.description,
            paymentHash: parsedInvoice.paymentHash ?? "",
            timestamp: parsedInvoice.timestamp ?? 0,
            state: parsedInvoice.expired ? WalletInvoiceState.Expired : WalletInvoiceState.Pending,
            pr,
        };
    }
}
export class WalletStore extends ExternalStore {
    constructor() {
        super();
        _WalletStore_instances.add(this);
        _WalletStore_configs.set(this, void 0);
        _WalletStore_instance.set(this, void 0);
        __classPrivateFieldSet(this, _WalletStore_configs, [], "f");
        __classPrivateFieldSet(this, _WalletStore_instance, new Map(), "f");
        this.load(false);
        setupWebLNWalletConfig(this);
        this.notifyChange();
    }
    list() {
        return Object.freeze([...__classPrivateFieldGet(this, _WalletStore_configs, "f")]);
    }
    get() {
        const activeConfig = __classPrivateFieldGet(this, _WalletStore_configs, "f").find(a => a.active);
        if (!activeConfig) {
            if (__classPrivateFieldGet(this, _WalletStore_configs, "f").length === 0) {
                return undefined;
            }
            throw new Error("No active wallet config");
        }
        if (__classPrivateFieldGet(this, _WalletStore_instance, "f").has(activeConfig.id)) {
            return unwrap(__classPrivateFieldGet(this, _WalletStore_instance, "f").get(activeConfig.id));
        }
        else {
            const w = __classPrivateFieldGet(this, _WalletStore_instances, "m", _WalletStore_activateWallet).call(this, activeConfig);
            if (w) {
                if ("then" in w) {
                    w.then(async (wx) => {
                        __classPrivateFieldGet(this, _WalletStore_instance, "f").set(activeConfig.id, wx);
                        this.notifyChange();
                    });
                    return undefined;
                }
                return w;
            }
            else {
                throw new Error("Unable to activate wallet config");
            }
        }
    }
    add(cfg) {
        __classPrivateFieldGet(this, _WalletStore_configs, "f").push(cfg);
        this.save();
    }
    remove(id) {
        const idx = __classPrivateFieldGet(this, _WalletStore_configs, "f").findIndex(a => a.id === id);
        if (idx === -1) {
            throw new Error("Wallet not found");
        }
        const [removed] = __classPrivateFieldGet(this, _WalletStore_configs, "f").splice(idx, 1);
        if (removed.active && __classPrivateFieldGet(this, _WalletStore_configs, "f").length > 0) {
            __classPrivateFieldGet(this, _WalletStore_configs, "f")[0].active = true;
        }
        this.save();
    }
    switch(id) {
        __classPrivateFieldGet(this, _WalletStore_configs, "f").forEach(a => (a.active = a.id === id));
        this.save();
    }
    save() {
        const json = JSON.stringify(__classPrivateFieldGet(this, _WalletStore_configs, "f"));
        window.localStorage.setItem("wallet-config", json);
        this.notifyChange();
    }
    load(snapshot = true) {
        const cfg = window.localStorage.getItem("wallet-config");
        if (cfg) {
            __classPrivateFieldSet(this, _WalletStore_configs, JSON.parse(cfg), "f");
        }
        if (snapshot) {
            this.notifyChange();
        }
    }
    free() {
        __classPrivateFieldGet(this, _WalletStore_instance, "f").forEach(w => w.close());
    }
    takeSnapshot() {
        return {
            configs: [...__classPrivateFieldGet(this, _WalletStore_configs, "f")],
            config: __classPrivateFieldGet(this, _WalletStore_configs, "f").find(a => a.active),
            wallet: this.get(),
        };
    }
}
_WalletStore_configs = new WeakMap(), _WalletStore_instance = new WeakMap(), _WalletStore_instances = new WeakSet(), _WalletStore_activateWallet = function _WalletStore_activateWallet(cfg) {
    switch (cfg.kind) {
        case WalletKind.LNC: {
            return import("./LNCWallet").then(({ LNCWallet }) => LNCWallet.Empty());
        }
        case WalletKind.WebLN: {
            return new WebLNWallet();
        }
        case WalletKind.LNDHub: {
            return new LNDHubWallet(unwrap(cfg.data));
        }
        case WalletKind.NWC: {
            return new NostrConnectWallet(unwrap(cfg.data));
        }
        case WalletKind.Cashu: {
            return import("./Cashu").then(({ CashuWallet }) => new CashuWallet(unwrap(cfg.data)));
        }
    }
};
export const Wallets = new WalletStore();
window.document.addEventListener("close", () => {
    Wallets.free();
});
export function useWallet() {
    const wallet = useSyncExternalStore(h => Wallets.hook(h), () => Wallets.snapshot());
    useEffect(() => {
        if (wallet.wallet?.isReady() === false && wallet.wallet.canAutoLogin()) {
            wallet.wallet.login().catch(console.error);
        }
    }, [wallet]);
    return wallet;
}
