import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
export default function Reveal(props) {
    const [reveal, setReveal] = useState(false);
    if (!reveal) {
        return (_jsx("div", { onClick: e => {
                e.stopPropagation();
                setReveal(true);
            }, className: "note-invoice", children: props.message }));
    }
    else {
        return _jsx(_Fragment, { children: props.children });
    }
}
