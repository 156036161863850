import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./Root.css";
import { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import Tabs from "Element/Tabs";
import Timeline from "Element/Timeline";
import { System } from "index";
import { debounce, getRelayName, sha256, unixNow, unwrap } from "SnortUtils";
import useLogin from "Hooks/useLogin";
import Discover from "Pages/Discover";
import messages from "./messages";
export default function RootPage() {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const { publicKey: pubKey, tags, preferences } = useLogin();
    const RootTab = {
        Notes: {
            text: formatMessage(messages.Notes),
            value: 0,
            data: "/notes",
        },
        Conversations: {
            text: formatMessage(messages.Conversations),
            value: 1,
            data: "/conversations",
        },
        Global: {
            text: formatMessage(messages.Global),
            value: 2,
            data: "/global",
        },
        Discover: {
            text: formatMessage({ id: "cE4Hfw", defaultMessage: "Discover" }),
            value: 3,
            data: "/discover",
        },
    };
    const tagTabs = tags.item.map((t, idx) => {
        return { text: `#${t}`, value: idx + 3, data: `/tag/${t}` };
    });
    const tabs = [RootTab.Notes, RootTab.Conversations, RootTab.Global, RootTab.Discover, ...tagTabs];
    const tab = useMemo(() => {
        const pTab = location.pathname.split("/").slice(-1)[0];
        if (location.pathname.startsWith("/tag")) {
            const selectedTag = tagTabs.find(t => t.text.slice(1) === pTab);
            if (selectedTag) {
                return selectedTag;
            }
        }
        switch (pTab) {
            case "conversations": {
                return RootTab.Conversations;
            }
            case "global": {
                return RootTab.Global;
            }
            case "discover": {
                return RootTab.Discover;
            }
            default: {
                return RootTab.Notes;
            }
        }
    }, [location]);
    useEffect(() => {
        if (location.pathname === "/") {
            const t = pubKey ? preferences.defaultRootTab ?? tab.data : "/global";
            navigate(t, {
                replace: true,
            });
        }
    }, [location]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "main-content", children: pubKey && _jsx(Tabs, { tabs: tabs, tab: tab, setTab: t => navigate(unwrap(t.data)) }) }), _jsx("div", { className: "main-content", children: _jsx(Outlet, {}) })] }));
}
const FollowsHint = () => {
    const { publicKey: pubKey, follows } = useLogin();
    if (follows.item?.length === 0 && pubKey) {
        return (_jsx(FormattedMessage, { ...messages.NoFollows, values: {
                newUsersPage: (_jsx(Link, { to: "/new/discover", children: _jsx(FormattedMessage, { ...messages.NewUsers }) })),
            } }));
    }
    return null;
};
const GlobalTab = () => {
    const { relays } = useLogin();
    const [relay, setRelay] = useState();
    const [allRelays, setAllRelays] = useState();
    const [now] = useState(unixNow());
    const subject = {
        type: "global",
        items: [],
        relay: relay?.url,
        discriminator: `all-${sha256(relay?.url ?? "").slice(0, 12)}`,
    };
    function globalRelaySelector() {
        if (!allRelays || allRelays.length === 0)
            return null;
        const paidRelays = allRelays.filter(a => a.paid);
        const publicRelays = allRelays.filter(a => !a.paid);
        return (_jsxs("div", { className: "flex mb10 f-end nowrap", children: [_jsx(FormattedMessage, { id: 'yCmnnm', defaultMessage: 'Read global from' }), "\u00A0", _jsxs("select", { className: "f-ellipsis", onChange: e => setRelay(allRelays.find(a => a.url === e.target.value)), value: relay?.url, children: [paidRelays.length > 0 && (_jsx("optgroup", { label: "Paid Relays", children: paidRelays.map(a => (_jsx("option", { value: a.url, children: getRelayName(a.url) }, a.url))) })), _jsx("optgroup", { label: "Public Relays", children: publicRelays.map(a => (_jsx("option", { value: a.url, children: getRelayName(a.url) }, a.url))) })] })] }));
    }
    useEffect(() => {
        return debounce(500, () => {
            const ret = [];
            System.Sockets.forEach(v => {
                ret.push({
                    url: v.address,
                    paid: v.info?.limitation?.payment_required ?? false,
                });
            });
            ret.sort(a => (a.paid ? -1 : 1));
            if (ret.length > 0 && !relay) {
                setRelay(ret[0]);
            }
            setAllRelays(ret);
        });
    }, [relays, relay]);
    return (_jsxs(_Fragment, { children: [globalRelaySelector(), relay && _jsx(Timeline, { subject: subject, postsOnly: false, method: "TIME_RANGE", window: 600, now: now })] }));
};
const NotesTab = () => {
    const { follows, publicKey } = useLogin();
    const subject = {
        type: "pubkey",
        items: follows.item,
        discriminator: `follows:${publicKey?.slice(0, 12)}`,
    };
    return (_jsxs(_Fragment, { children: [_jsx(FollowsHint, {}), _jsx(Timeline, { subject: subject, postsOnly: true, method: "TIME_RANGE" })] }));
};
const ConversationsTab = () => {
    const { follows, publicKey } = useLogin();
    const subject = {
        type: "pubkey",
        items: follows.item,
        discriminator: `follows:${publicKey?.slice(0, 12)}`,
    };
    return _jsx(Timeline, { subject: subject, postsOnly: false, method: "TIME_RANGE" });
};
const TagsTab = () => {
    const { tag } = useParams();
    const subject = { type: "hashtag", items: [tag ?? ""], discriminator: `tags-${tag}` };
    return _jsx(Timeline, { subject: subject, postsOnly: false, method: "TIME_RANGE" });
};
export const RootRoutes = [
    {
        path: "/",
        element: _jsx(RootPage, {}),
        children: [
            {
                path: "global",
                element: _jsx(GlobalTab, {}),
            },
            {
                path: "notes",
                element: _jsx(NotesTab, {}),
            },
            {
                path: "conversations",
                element: _jsx(ConversationsTab, {}),
            },
            {
                path: "discover",
                element: _jsx(Discover, {}),
            },
            {
                path: "tag/:tag",
                element: _jsx(TagsTab, {}),
            },
        ],
    },
];
