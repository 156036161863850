import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./LiveChat.css";
import { useUserProfile } from "@snort/system-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Textarea from "Element/Textarea";
import { useLiveChatFeed } from "Feed/LiveChatFeed";
import useEventPublisher from "Feed/EventPublisher";
import { getDisplayName } from "Element/ProfileImage";
import Avatar from "Element/Avatar";
import AsyncButton from "Element/AsyncButton";
import Text from "Element/Text";
import { System } from "index";
import { profileLink } from "SnortUtils";
export function LiveChat({ ev, link }) {
    const [chat, setChat] = useState("");
    const messages = useLiveChatFeed(link);
    const pub = useEventPublisher();
    const { formatMessage } = useIntl();
    async function sendChatMessage() {
        if (chat.length > 1) {
            const reply = await pub?.generic(eb => {
                return eb
                    .kind(1311)
                    .content(chat)
                    .tag(["a", `${link.kind}:${link.author}:${link.id}`])
                    .processContent();
            });
            if (reply) {
                console.debug(reply);
                System.BroadcastEvent(reply);
            }
            setChat("");
        }
    }
    return (_jsxs("div", { className: "live-chat", children: [_jsx("div", { children: _jsx(FormattedMessage, { id: 'BGxpTN', defaultMessage: 'Stream Chat' }) }), _jsx("div", { children: [...(messages.data ?? [])]
                    .sort((a, b) => b.created_at - a.created_at)
                    .map(a => (_jsx(ChatMessage, { ev: a }, a.id))) }), _jsxs("div", { children: [_jsx(Textarea, { autoFocus: false, className: "", onChange: v => setChat(v.target.value), value: chat, onFocus: () => { }, placeholder: formatMessage({ id: "qInqHy", defaultMessage: "Message..." }), onKeyDown: async (e) => {
                            if (e.code === "Enter") {
                                e.preventDefault();
                                await sendChatMessage();
                            }
                        } }), _jsx(AsyncButton, { onClick: sendChatMessage, children: _jsx(FormattedMessage, { id: '9WRlF4', defaultMessage: 'Send' }) })] })] }));
}
function ChatMessage({ ev }) {
    const profile = useUserProfile(System, ev.pubkey);
    const navigate = useNavigate();
    return (_jsxs("div", { className: "message", children: [_jsxs("div", { className: "name", onClick: () => navigate(profileLink(ev.pubkey, ev.relays)), children: [_jsx(Avatar, { user: profile }), getDisplayName(profile, ev.pubkey), ":"] }), _jsx("span", { children: _jsx(Text, { disableMedia: true, content: ev.content, creator: ev.pubkey, tags: ev.tags }) })] }));
}
