import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./index.css";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { formatShort } from "Number";
import { LockedFeatures, Plans, SubscriptionType } from "Subscription";
import ManageSubscriptionPage from "Pages/subscribe/ManageSubscription";
import AsyncButton from "Element/AsyncButton";
import useEventPublisher from "Feed/EventPublisher";
import SnortApi, { SubscriptionError, SubscriptionErrorCode } from "SnortApi";
import SendSats from "Element/SendSats";
export function mapPlanName(id) {
    switch (id) {
        case SubscriptionType.Supporter:
            return _jsx(FormattedMessage, { id: 'DcL8P+', defaultMessage: 'Supporter' });
        case SubscriptionType.Premium:
            return _jsx(FormattedMessage, { id: 'C5xzTC', defaultMessage: 'Premium' });
    }
}
export function mapFeatureName(k) {
    switch (k) {
        case LockedFeatures.MultiAccount:
            return _jsx(FormattedMessage, { id: 'cuP16y', defaultMessage: 'Multi account support' });
        case LockedFeatures.NostrAddress:
            return _jsx(FormattedMessage, { id: 'lPWASz', defaultMessage: 'Snort nostr address' });
        case LockedFeatures.Badge:
            return _jsx(FormattedMessage, { id: 'ttxS0b', defaultMessage: 'Supporter Badge' });
        case LockedFeatures.DeepL:
            return _jsx(FormattedMessage, { id: 'iEoXYx', defaultMessage: 'DeepL translations' });
        case LockedFeatures.RelayRetention:
            return _jsx(FormattedMessage, { id: 'Ai8VHU', defaultMessage: 'Unlimited note retention on Snort relay' });
        case LockedFeatures.RelayBackup:
            return _jsx(FormattedMessage, { id: 'pI+77w', defaultMessage: 'Downloadable backups from Snort relay' });
        case LockedFeatures.RelayAccess:
            return _jsx(FormattedMessage, { id: 'BGCM48', defaultMessage: 'Write access to Snort relay, with 1 year of event retention' });
        case LockedFeatures.LNProxy:
            return _jsx(FormattedMessage, { id: 'SYQtZ7', defaultMessage: 'LN Address Proxy' });
        case LockedFeatures.EmailBridge:
            return _jsx(FormattedMessage, { id: 'qD9EUF', defaultMessage: 'Email <> DM bridge for your Snort nostr address' });
    }
}
export function mapSubscriptionErrorCode(c) {
    switch (c.code) {
        case SubscriptionErrorCode.InternalError:
            return _jsx(FormattedMessage, { id: 'jMzO1S', defaultMessage: 'Internal error: {msg}', values: { msg: c.message } });
        case SubscriptionErrorCode.SubscriptionActive:
            return _jsx(FormattedMessage, { id: 'OQXnew', defaultMessage: 'You subscription is still active, you can\'t renew yet' });
        case SubscriptionErrorCode.Duplicate:
            return _jsx(FormattedMessage, { id: 'NAuFNH', defaultMessage: 'You already have a subscription of this type, please renew or pay' });
        default:
            return c.message;
    }
}
export function SubscribePage() {
    const publisher = useEventPublisher();
    const api = new SnortApi(undefined, publisher);
    const [invoice, setInvoice] = useState("");
    const [error, setError] = useState();
    async function subscribe(type) {
        setError(undefined);
        try {
            const rsp = await api.createSubscription(type);
            setInvoice(rsp.pr);
        }
        catch (e) {
            if (e instanceof SubscriptionError) {
                setError(e);
            }
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "flex subscribe-page", children: Plans.map(a => {
                    const lower = Plans.filter(b => b.id < a.id);
                    return (_jsxs("div", { className: `card flex f-col${a.disabled ? " disabled" : ""}`, children: [_jsxs("div", { className: "f-grow", children: [_jsx("h2", { children: mapPlanName(a.id) }), _jsxs("p", { children: [_jsx(FormattedMessage, { id: 'Z0FDj+', defaultMessage: 'Subscribe to Snort {plan} for {price} and receive the following rewards', values: {
                                                    plan: mapPlanName(a.id),
                                                    price: _jsxs("b", { children: [formatShort(a.price), " sats/mo"] }),
                                                } }), ":"] }), _jsxs("ul", { children: [a.unlocks.map(b => (_jsxs("li", { children: [mapFeatureName(b), " "] }))), lower.map(b => (_jsx("li", { children: _jsx(FormattedMessage, { id: 'l+ikU1', defaultMessage: 'Everything in {plan}', values: {
                                                        plan: mapPlanName(b.id),
                                                    } }) })))] })] }), _jsx("div", { className: "flex f-center w-max mb10", children: _jsx(AsyncButton, { className: "button", disabled: a.disabled, onClick: () => subscribe(a.id), children: a.disabled ? (_jsx(FormattedMessage, { id: 'e61Jf3', defaultMessage: 'Coming soon' })) : (_jsx(FormattedMessage, { id: 'gczcC5', defaultMessage: 'Subscribe' })) }) })] }));
                }) }), error && _jsx("b", { className: "error", children: mapSubscriptionErrorCode(error) }), _jsx(SendSats, { invoice: invoice, show: invoice !== "", onClose: () => setInvoice("") })] }));
}
export const SubscribeRoutes = [
    {
        path: "/subscribe",
        element: _jsx(SubscribePage, {}),
    },
    {
        path: "/subscribe/manage",
        element: _jsx(ManageSubscriptionPage, {}),
    },
];
