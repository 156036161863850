import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import PageSpinner from "Element/PageSpinner";
import useEventPublisher from "Feed/EventPublisher";
import SnortApi, { SubscriptionError } from "SnortApi";
import { mapSubscriptionErrorCode } from ".";
import SubscriptionCard from "./SubscriptionCard";
export default function ManageSubscriptionPage() {
    const publisher = useEventPublisher();
    const api = new SnortApi(undefined, publisher);
    const navigate = useNavigate();
    const [subs, setSubs] = useState();
    const [error, setError] = useState();
    useEffect(() => {
        (async () => {
            try {
                const s = await api.listSubscriptions();
                setSubs(s);
            }
            catch (e) {
                if (e instanceof SubscriptionError) {
                    setError(e);
                }
            }
        })();
    }, []);
    if (subs === undefined) {
        return _jsx(PageSpinner, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx("h2", { children: _jsx(FormattedMessage, { id: 'J+dIsA', defaultMessage: 'Subscriptions' }) }), subs.map(a => (_jsx(SubscriptionCard, { sub: a }, a.id))), subs.length !== 0 && (_jsx("button", { onClick: () => navigate("/subscribe"), children: _jsx(FormattedMessage, { id: 'SP0+yi', defaultMessage: 'Buy Subscription' }) })), subs.length === 0 && (_jsx("p", { children: _jsx(FormattedMessage, { id: 'W1yoZY', defaultMessage: 'It looks like you dont have any subscriptions, you can get one {link}', values: {
                        link: (_jsx(Link, { to: "/subscribe", children: _jsx(FormattedMessage, { id: 'hniz8Z', defaultMessage: 'here' }) })),
                    } }) })), error && _jsx("b", { className: "error", children: mapSubscriptionErrorCode(error) })] }));
}
