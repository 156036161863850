import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./WalletPage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import NoteTime from "Element/NoteTime";
import { WalletInvoiceState, useWallet, Wallets } from "Wallet";
import AsyncButton from "Element/AsyncButton";
import { unwrap } from "SnortUtils";
import { WebLNWallet } from "Wallet/WebLN";
import Icon from "Icons/Icon";
export const WalletRoutes = [
    {
        path: "/wallet",
        element: _jsx(WalletPage, {}),
    },
];
export default function WalletPage() {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [info, setInfo] = useState();
    const [balance, setBalance] = useState();
    const [history, setHistory] = useState();
    const [walletPassword, setWalletPassword] = useState();
    const [error, setError] = useState();
    const walletState = useWallet();
    const wallet = walletState.wallet;
    async function loadWallet(wallet) {
        try {
            const i = await wallet.getInfo();
            setInfo(i);
            const b = await wallet.getBalance();
            setBalance(b);
            const h = await wallet.getInvoices();
            setHistory(h.sort((a, b) => b.timestamp - a.timestamp));
        }
        catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            }
            else {
                setError(formatMessage({ id: "qDwvZ4", defaultMessage: "Unknown error" }));
            }
        }
    }
    useEffect(() => {
        if (wallet) {
            if (wallet.isReady()) {
                loadWallet(wallet).catch(console.warn);
            }
            else if (wallet.canAutoLogin()) {
                wallet
                    .login()
                    .then(async () => await loadWallet(wallet))
                    .catch(console.warn);
            }
        }
    }, [wallet]);
    function stateIcon(s) {
        switch (s) {
            case WalletInvoiceState.Pending:
                return _jsx(Icon, { name: "clock", className: "mr5", size: 15 });
            case WalletInvoiceState.Paid:
                return _jsx(Icon, { name: "check", className: "mr5", size: 15 });
            case WalletInvoiceState.Expired:
                return _jsx(Icon, { name: "close", className: "mr5", size: 15 });
        }
    }
    async function loginWallet(pw) {
        if (wallet) {
            await wallet.login(pw);
            await loadWallet(wallet);
            setWalletPassword(undefined);
        }
    }
    function unlockWallet() {
        if (!wallet || wallet.isReady())
            return null;
        return (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'r5srDR', defaultMessage: 'Enter wallet password' }) }), _jsxs("div", { className: "flex w-max", children: [_jsx("div", { className: "f-grow mr10", children: _jsx("input", { type: "password", placeholder: formatMessage({ id: "MP54GY", defaultMessage: "Wallet password" }), className: "w-max", value: walletPassword, onChange: e => setWalletPassword(e.target.value) }) }), _jsx(AsyncButton, { onClick: () => loginWallet(unwrap(walletPassword)), disabled: (walletPassword?.length ?? 0) < 8, children: _jsx(FormattedMessage, { id: 'xQtL3v', defaultMessage: 'Unlock' }) })] })] }));
    }
    function walletList() {
        if (walletState.configs.length === 0) {
            return (_jsx("button", { onClick: () => navigate("/settings/wallet"), children: _jsx(FormattedMessage, { id: 'cg1VJ2', defaultMessage: 'Connect Wallet' }) }));
        }
        return (_jsxs("div", { className: "flex w-max", children: [_jsx("h4", { className: "f-1", children: _jsx(FormattedMessage, { id: 'G1BGCg', defaultMessage: 'Select Wallet' }) }), _jsx("div", { className: "f-1", children: _jsx("select", { className: "w-max", onChange: e => Wallets.switch(e.target.value), value: walletState.config?.id, children: Wallets.list().map(a => {
                            return _jsx("option", { value: a.id, children: a.info.alias });
                        }) }) })] }));
    }
    function walletHistory() {
        if (wallet instanceof WebLNWallet)
            return null;
        return (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'd6CyG5', defaultMessage: 'History' }) }), history?.map(a => (_jsxs("div", { className: "card flex wallet-history-item", children: [_jsxs("div", { className: "f-grow f-col", children: [_jsx(NoteTime, { from: a.timestamp * 1000, fallback: formatMessage({ id: "kaaf1E", defaultMessage: "now" }) }), _jsx("div", { children: (a.memo ?? "").length === 0 ? _jsx(_Fragment, { children: "\u00A0" }) : a.memo })] }), _jsxs("div", { className: `nowrap ${(() => {
                                switch (a.state) {
                                    case WalletInvoiceState.Paid:
                                        return "success";
                                    case WalletInvoiceState.Expired:
                                        return "expired";
                                    case WalletInvoiceState.Failed:
                                        return "failed";
                                    default:
                                        return "pending";
                                }
                            })()}`, children: [stateIcon(a.state), _jsx(FormattedMessage, { id: 'vrTOHJ', defaultMessage: '{amount} sats', values: {
                                        amount: _jsx(FormattedNumber, { value: a.amount / 1e3 }),
                                    } })] })] }, a.timestamp)))] }));
    }
    function walletBalance() {
        if (wallet instanceof WebLNWallet)
            return null;
        return (_jsx("small", { children: _jsx(FormattedMessage, { id: 'VN0+Fz', defaultMessage: 'Balance: {amount} sats', values: {
                    amount: _jsx(FormattedNumber, { value: balance ?? 0 }),
                } }) }));
    }
    function walletInfo() {
        if (!wallet?.isReady())
            return null;
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "card", children: [_jsx("h3", { children: info?.alias }), walletBalance()] }), walletHistory()] }));
    }
    return (_jsxs("div", { className: "main-content", children: [error && _jsx("b", { className: "error", children: error }), walletList(), unlockWallet(), walletInfo(), _jsx("button", { onClick: () => Wallets.remove(unwrap(walletState.config).id), children: _jsx(FormattedMessage, { id: 'R2OqnW', defaultMessage: 'Delete Account' }) })] }));
}
