import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "@webscopeio/react-textarea-autocomplete/style.css";
import "./Textarea.css";
import { useIntl } from "react-intl";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import TextareaAutosize from "react-textarea-autosize";
import { NostrPrefix } from "@snort/system";
import Avatar from "Element/Avatar";
import Nip05 from "Element/Nip05";
import { hexToBech32 } from "SnortUtils";
import { UserCache } from "Cache";
import messages from "./messages";
const EmojiItem = ({ entity: { name, char } }) => {
    return (_jsxs("div", { className: "emoji-item", children: [_jsx("div", { className: "emoji", children: char }), _jsx("div", { className: "emoji-name", children: name })] }));
};
const UserItem = (metadata) => {
    const { pubkey, display_name, nip05, ...rest } = metadata;
    return (_jsxs("div", { className: "user-item", children: [_jsx("div", { className: "user-picture", children: _jsx(Avatar, { user: metadata }) }), _jsxs("div", { className: "user-details", children: [_jsx("strong", { children: display_name || rest.name }), _jsx(Nip05, { nip05: nip05, pubkey: pubkey })] })] }, pubkey));
};
const Textarea = (props) => {
    const { formatMessage } = useIntl();
    const userDataProvider = async (token) => {
        return await UserCache.search(token);
    };
    const emojiDataProvider = async (token) => {
        const emoji = await import("@jukben/emoji-search");
        return emoji
            .default(token)
            .slice(0, 5)
            .map(({ name, char }) => ({ name, char }));
    };
    return (
    // @ts-expect-error If anybody can figure out how to type this, please do
    _jsx(ReactTextareaAutocomplete, { dir: "auto", ...props, loadingComponent: () => _jsx("span", { children: "Loading..." }), placeholder: props.placeholder ?? formatMessage(messages.NotePlaceholder), textAreaComponent: TextareaAutosize, trigger: {
            ":": {
                dataProvider: emojiDataProvider,
                component: EmojiItem,
                output: (item) => item.char,
            },
            "@": {
                afterWhitespace: true,
                dataProvider: userDataProvider,
                component: (props) => _jsx(UserItem, { ...props.entity }),
                output: (item) => `@${hexToBech32(NostrPrefix.PublicKey, item.pubkey)}`,
            },
        } }));
};
export default Textarea;
