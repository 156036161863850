import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { db } from "Db";
import AsyncButton from "Element/AsyncButton";
import { FormattedMessage } from "react-intl";
import { useRouteError } from "react-router-dom";
const ErrorPage = () => {
    const error = useRouteError();
    console.error(error);
    return (_jsxs("div", { className: "main-content page", children: [_jsx("h4", { children: _jsx(FormattedMessage, { id: 'FfYsOb', defaultMessage: 'An error has occured!' }) }), _jsx(AsyncButton, { onClick: async () => {
                    await db.delete();
                    globalThis.localStorage.clear();
                    globalThis.location.href = "/";
                }, children: _jsx(FormattedMessage, { id: 'HWbkEK', defaultMessage: 'Clear cache and reload' }) }), _jsx("pre", { children: JSON.stringify(error instanceof Error ? { name: error.name, message: error.message, stack: error.stack } : error, undefined, "  ") })] }));
};
export default ErrorPage;
