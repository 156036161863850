import { jsx as _jsx } from "react/jsx-runtime";
import "./FollowButton.css";
import { FormattedMessage } from "react-intl";
import useEventPublisher from "Feed/EventPublisher";
import { parseId } from "SnortUtils";
import useLogin from "Hooks/useLogin";
import AsyncButton from "Element/AsyncButton";
import { System } from "index";
import messages from "./messages";
export default function FollowButton(props) {
    const pubkey = parseId(props.pubkey);
    const publisher = useEventPublisher();
    const { follows, relays } = useLogin();
    const isFollowing = follows.item.includes(pubkey);
    const baseClassname = `${props.className} follow-button`;
    async function follow(pubkey) {
        if (publisher) {
            const ev = await publisher.contactList([pubkey, ...follows.item], relays.item);
            System.BroadcastEvent(ev);
        }
    }
    async function unfollow(pubkey) {
        if (publisher) {
            const ev = await publisher.contactList(follows.item.filter(a => a !== pubkey), relays.item);
            System.BroadcastEvent(ev);
        }
    }
    return (_jsx(AsyncButton, { className: isFollowing ? `${baseClassname} secondary` : baseClassname, onClick: () => (isFollowing ? unfollow(pubkey) : follow(pubkey)), children: isFollowing ? _jsx(FormattedMessage, { ...messages.Unfollow }) : _jsx(FormattedMessage, { ...messages.Follow }) }));
}
