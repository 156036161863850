var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _BaseUITask_instances, _BaseUITask_save;
export class BaseUITask {
    constructor() {
        _BaseUITask_instances.add(this);
        this.state = {};
    }
    mute() {
        this.state.muted = true;
        __classPrivateFieldGet(this, _BaseUITask_instances, "m", _BaseUITask_save).call(this);
    }
    load() {
        const state = window.localStorage.getItem(`task:${this.id}`);
        if (state) {
            this.state = JSON.parse(state);
        }
    }
}
_BaseUITask_instances = new WeakSet(), _BaseUITask_save = function _BaseUITask_save() {
    window.localStorage.setItem(`task:${this.id}`, JSON.stringify(this.state));
};
