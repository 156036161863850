import { prToWalletInvoice, WalletError, WalletErrorCode, WalletInvoiceState, } from "Wallet";
const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
};
export default class LNDHubWallet {
    constructor(url) {
        if (url.startsWith("lndhub://")) {
            const regex = /^lndhub:\/\/([\S-]+):([\S-]+)@(.*)$/i;
            const parsedUrl = url.match(regex);
            if (!parsedUrl || parsedUrl.length !== 4) {
                throw new Error("Invalid LNDHUB config");
            }
            this.url = new URL(parsedUrl[3]);
            this.user = parsedUrl[1];
            this.password = parsedUrl[2];
            this.type = "lndhub";
        }
        else {
            throw new Error("Invalid config");
        }
    }
    isReady() {
        return this.auth !== undefined;
    }
    canAutoLogin() {
        return true;
    }
    close() {
        return Promise.resolve(true);
    }
    async getInfo() {
        return await this.getJson("GET", "/getinfo");
    }
    async login() {
        const rsp = await this.getJson("POST", "/auth?type=auth", {
            login: this.user,
            password: this.password,
        });
        this.auth = rsp;
        return true;
    }
    async getBalance() {
        const rsp = await this.getJson("GET", "/balance");
        const bal = Math.floor(rsp.BTC.AvailableBalance);
        return bal;
    }
    async createInvoice(req) {
        const rsp = await this.getJson("POST", "/addinvoice", {
            amt: req.amount,
            memo: req.memo,
        });
        const pRsp = rsp;
        return {
            pr: pRsp.payment_request,
            memo: req.memo,
            amount: req.amount,
            paymentHash: pRsp.payment_hash,
            timestamp: pRsp.timestamp,
        };
    }
    async payInvoice(pr) {
        const rsp = await this.getJson("POST", "/payinvoice", {
            invoice: pr,
        });
        const pRsp = rsp;
        return {
            pr: pr,
            paymentHash: pRsp.payment_hash,
            preimage: pRsp.payment_preimage,
            state: pRsp.payment_error
                ? WalletInvoiceState.Failed
                : pRsp.payment_preimage
                    ? WalletInvoiceState.Paid
                    : WalletInvoiceState.Pending,
        };
    }
    async getInvoices() {
        const rsp = await this.getJson("GET", "/getuserinvoices");
        return rsp.map(a => {
            const decodedInvoice = prToWalletInvoice(a.payment_request);
            if (!decodedInvoice) {
                throw new WalletError(WalletErrorCode.InvalidInvoice, "Failed to parse invoice");
            }
            return {
                ...decodedInvoice,
                state: a.ispaid ? WalletInvoiceState.Paid : decodedInvoice.state,
                paymentHash: a.payment_hash,
                memo: a.description,
            };
        });
    }
    async getJson(method, path, body) {
        const auth = `Bearer ${this.auth?.access_token}`;
        const url = `${this.url.pathname === "/" ? this.url.toString().slice(0, -1) : this.url.toString()}${path}`;
        const rsp = await fetch(url, {
            method: method,
            body: body ? JSON.stringify(body) : undefined,
            headers: {
                ...defaultHeaders,
                Authorization: auth,
            },
        });
        const json = await rsp.json();
        if ("code" in json && !rsp.ok) {
            const err = json;
            throw new WalletError(err.code, err.message);
        }
        return json;
    }
}
