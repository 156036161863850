import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import Icon from "Icons/Icon";
import ShowMore from "Element/ShowMore";
const Collapsed = ({ text, children, collapsed, setCollapsed }) => {
    return collapsed ? (_jsx("div", { className: "collapsed", children: _jsx(ShowMore, { text: text, onClick: () => setCollapsed(false) }) })) : (_jsx("div", { className: "uncollapsed", children: children }));
};
export const CollapsedIcon = ({ icon, collapsed }) => {
    return collapsed ? _jsx("div", { className: "collapsed", children: icon }) : _jsx("div", { className: "uncollapsed", children: icon });
};
export const CollapsedSection = ({ title, children, className }) => {
    const [collapsed, setCollapsed] = useState(true);
    const icon = (_jsx("div", { className: `collapse-icon ${collapsed ? "" : "flip"}`, children: _jsx(Icon, { name: "arrowFront" }) }));
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: `collapsable-section${className ? ` ${className}` : ""}`, onClick: () => setCollapsed(!collapsed), children: [title, _jsx(CollapsedIcon, { icon: icon, collapsed: collapsed })] }), !collapsed && children] }));
};
export default Collapsed;
