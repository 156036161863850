import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ProfilePreview from "Element/ProfilePreview";
import { LoginStore } from "Login";
import useLoginHandler from "Hooks/useLoginHandler";
import AsyncButton from "Element/AsyncButton";
import { getActiveSubscriptions } from "Subscription";
export default function AccountsPage() {
    const { formatMessage } = useIntl();
    const [key, setKey] = useState("");
    const [error, setError] = useState("");
    const loginHandler = useLoginHandler();
    const logins = LoginStore.getSessions();
    const sub = getActiveSubscriptions(LoginStore.allSubscriptions());
    async function doLogin() {
        try {
            setError("");
            await loginHandler.doLogin(key);
            setKey("");
        }
        catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            }
            else {
                setError(formatMessage({ id: "OLEm6z", defaultMessage: "Unknown login error" }));
            }
            console.error(e);
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: '+vA//S', defaultMessage: 'Logins' }) }), logins.map(a => (_jsx("div", { className: "card flex", children: _jsx(ProfilePreview, { pubkey: a, options: {
                        about: false,
                    }, actions: _jsxs("div", { className: "f-1", children: [_jsx("button", { className: "mb10", onClick: () => LoginStore.switchAccount(a), children: _jsx(FormattedMessage, { id: 'n1Whvj', defaultMessage: 'Switch' }) }), _jsx("button", { onClick: () => LoginStore.removeSession(a), children: _jsx(FormattedMessage, { id: 'C81/uG', defaultMessage: 'Logout' }) })] }) }) }, a))), sub && (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'F3l7xL', defaultMessage: 'Add Account' }) }), _jsxs("div", { className: "flex", children: [_jsx("input", { dir: "auto", type: "text", placeholder: formatMessage({ id: "X7xU8J", defaultMessage: "nsec, npub, nip-05, hex, mnemonic" }), className: "f-grow mr10", onChange: e => setKey(e.target.value) }), _jsx(AsyncButton, { onClick: () => doLogin(), children: _jsx(FormattedMessage, { id: 'AyGauy', defaultMessage: 'Login' }) })] })] })), error && _jsx("b", { className: "error", children: error })] }));
}
