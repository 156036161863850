import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { ApiHost } from "Const";
import Nip5Service from "Element/Nip5Service";
import messages from "./messages";
import "./Verification.css";
export const SnortNostrAddressService = {
    name: "Snort",
    service: `${ApiHost}/api/v1/n5sp`,
    link: "https://snort.social/",
    supportLink: "https://snort.social/help",
    about: _jsx(FormattedMessage, { ...messages.SnortSocialNip }),
};
export const Nip5Services = [
    SnortNostrAddressService,
    {
        name: "Nostr Plebs",
        service: "https://nostrplebs.com/api/v1",
        link: "https://nostrplebs.com/",
        supportLink: "https://nostrplebs.com/manage",
        about: _jsx(FormattedMessage, { ...messages.NostrPlebsNip }),
    },
];
export default function VerificationPage() {
    return (_jsxs("div", { className: "main-content verification", children: [_jsx("h2", { children: _jsx(FormattedMessage, { ...messages.GetVerified }) }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.Nip05 }) }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.Nip05Pros }) }), _jsxs("ul", { children: [_jsx("li", { children: _jsx(FormattedMessage, { ...messages.AvoidImpersonators }) }), _jsx("li", { children: _jsx(FormattedMessage, { ...messages.EasierToFind }) }), _jsx("li", { children: _jsx(FormattedMessage, { ...messages.Funding }) })] }), Nip5Services.map(a => (_jsx(Nip5Service, { ...a }, a.name)))] }));
}
