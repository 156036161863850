import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PageSpinner from "Element/PageSpinner";
import Note from "Element/Note";
import NostrBandApi from "External/NostrBand";
export default function TrendingNotes() {
    const [posts, setPosts] = useState();
    async function loadTrendingNotes() {
        const api = new NostrBandApi();
        const trending = await api.trendingNotes();
        setPosts(trending.notes.map(a => a.event));
    }
    useEffect(() => {
        loadTrendingNotes().catch(console.error);
    }, []);
    if (!posts)
        return _jsx(PageSpinner, {});
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'Ix8l+B', defaultMessage: 'Trending Notes' }) }), posts.map(e => (_jsx(Note, { data: e, related: [], depth: 0 }, e.id)))] }));
}
