import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { findTag } from "SnortUtils";
import useEventFeed from "Feed/EventFeed";
import PageSpinner from "Element/PageSpinner";
import Reveal from "Element/Reveal";
import { MediaElement } from "Element/MediaElement";
export default function NostrFileHeader({ link }) {
    const ev = useEventFeed(link);
    if (!ev.data)
        return _jsx(PageSpinner, {});
    return _jsx(NostrFileElement, { ev: ev.data });
}
export function NostrFileElement({ ev }) {
    // assume image or embed which can be rendered by the hypertext kind
    // todo: make use of hash
    // todo: use magnet or other links if present
    const u = findTag(ev, "url");
    const x = findTag(ev, "x");
    const m = findTag(ev, "m");
    const blurHash = findTag(ev, "blurhash");
    const magnet = findTag(ev, "magnet");
    if (u && m) {
        return (_jsx(Reveal, { message: _jsx(FormattedMessage, { id: 'lsNFM1', defaultMessage: 'Click to load content from {link}', values: { link: u } }), children: _jsx(MediaElement, { mime: m, url: u, sha256: x, magnet: magnet, blurHash: blurHash }) }));
    }
    else {
        return (_jsx("b", { className: "error", children: _jsx(FormattedMessage, { id: 'PamNxw', defaultMessage: 'Unknown file header: {name}', values: { name: ev.content } }) }));
    }
}
