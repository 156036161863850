import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import useEventPublisher from "Feed/EventPublisher";
import ProfilePreview from "Element/ProfilePreview";
import useLogin from "Hooks/useLogin";
import { System } from "index";
import messages from "./messages";
export default function FollowListBase({ pubkeys, title, showFollowAll, showAbout, className, actions, }) {
    const publisher = useEventPublisher();
    const { follows, relays } = useLogin();
    async function followAll() {
        if (publisher) {
            const ev = await publisher.contactList([...pubkeys, ...follows.item], relays.item);
            System.BroadcastEvent(ev);
        }
    }
    return (_jsxs("div", { className: className, children: [(showFollowAll ?? true) && (_jsxs("div", { className: "flex mt10 mb10", children: [_jsx("div", { className: "f-grow bold", children: title }), actions, _jsx("button", { className: "transparent", type: "button", onClick: () => followAll(), children: _jsx(FormattedMessage, { ...messages.FollowAll }) })] })), pubkeys?.map(a => (_jsx(ProfilePreview, { pubkey: a, options: { about: showAbout } }, a)))] }));
}
