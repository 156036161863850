import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import AsyncButton from "Element/AsyncButton";
import { WalletKind, Wallets } from "Wallet";
import { unwrap } from "SnortUtils";
const ConnectLNC = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const [pairingPhrase, setPairingPhrase] = useState();
    const [error, setError] = useState();
    const [connectedLNC, setConnectedLNC] = useState();
    const [walletInfo, setWalletInfo] = useState();
    const [walletPassword, setWalletPassword] = useState();
    async function tryConnect(cfg) {
        try {
            const { LNCWallet } = await import("Wallet/LNCWallet");
            const lnc = await LNCWallet.Initialize(cfg);
            const info = await lnc.getInfo();
            // prompt password
            setConnectedLNC(lnc);
            setWalletInfo(info);
        }
        catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            }
            else {
                setError(formatMessage({ id: "qDwvZ4", defaultMessage: "Unknown error" }));
            }
        }
    }
    function setLNCPassword(pw) {
        connectedLNC?.setPassword(pw);
        Wallets.add({
            id: uuid(),
            kind: WalletKind.LNC,
            active: true,
            info: unwrap(walletInfo),
        });
        navigate("/wallet");
    }
    function flowConnect() {
        if (connectedLNC)
            return null;
        return (_jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(FormattedMessage, { id: 'Z4BMCZ', defaultMessage: 'Enter pairing phrase' }) }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "f-grow mr10", children: _jsx("input", { type: "text", placeholder: formatMessage({ id: "8v1NN+", defaultMessage: "Pairing phrase" }), className: "w-max", value: pairingPhrase, onChange: e => setPairingPhrase(e.target.value) }) }), _jsx(AsyncButton, { onClick: () => tryConnect(unwrap(pairingPhrase)), disabled: !pairingPhrase, children: _jsx(FormattedMessage, { id: '+vVZ/G', defaultMessage: 'Connect' }) })] }), error && _jsx("b", { className: "error p10", children: error })] }));
    }
    function flowSetPassword() {
        if (!connectedLNC)
            return null;
        return (_jsxs("div", { className: "flex f-col", children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: '1c4YST', defaultMessage: 'Connected to: {node} \uD83C\uDF89', values: {
                            node: walletInfo?.alias,
                        } }) }), _jsx("h4", { children: _jsx(FormattedMessage, { id: '2LbrkB', defaultMessage: 'Enter password' }) }), _jsxs("div", { className: "flex w-max", children: [_jsx("div", { className: "f-grow mr10", children: _jsx("input", { type: "password", placeholder: formatMessage({ id: "lTbT3s", defaultMessage: "Wallet password" }), className: "w-max", value: walletPassword, onChange: e => setWalletPassword(e.target.value) }) }), _jsx(AsyncButton, { onClick: () => setLNCPassword(unwrap(walletPassword)), disabled: (walletPassword?.length ?? 0) < 8, children: _jsx(FormattedMessage, { id: 'jvo0vs', defaultMessage: 'Save' }) })] })] }));
    }
    return (_jsxs(_Fragment, { children: [flowConnect(), flowSetPassword()] }));
};
export default ConnectLNC;
