import { jsx as _jsx } from "react/jsx-runtime";
import useImgProxy from "Hooks/useImgProxy";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { getUrlHostname } from "SnortUtils";
export const ProxyImg = (props) => {
    const { proxy } = useImgProxy();
    const [loadFailed, setLoadFailed] = useState(false);
    const [bypass, setBypass] = useState(false);
    if (loadFailed) {
        if (bypass) {
            return _jsx("img", { ...props, width: props.size, height: props.size });
        }
        return (_jsx("div", { className: "note-invoice error", onClick: e => {
                e.stopPropagation();
                setBypass(true);
            }, children: _jsx(FormattedMessage, { id: '65BmHb', defaultMessage: 'Failed to proxy image from {host}, click here to load directly', values: {
                    host: getUrlHostname(props.src),
                } }) }));
    }
    return (_jsx("img", { ...props, src: props.src ? proxy(props.src, props.size) : "", onError: e => {
            if (props.onError) {
                props.onError(e);
            }
            else {
                setLoadFailed(true);
            }
        } }));
};
