export class ServiceProvider {
    constructor(url) {
        this.url = url;
    }
    async GetConfig() {
        return await this.getJson("/config.json");
    }
    async CheckAvailable(handle, domain) {
        return await this.getJson("/registration/availability", "POST", {
            name: handle,
            domain,
        });
    }
    async RegisterHandle(handle, domain, pubkey) {
        return await this.getJson("/registration/register", "PUT", {
            name: handle,
            domain,
            pk: pubkey,
            ref: "snort",
        });
    }
    async CheckRegistration(token) {
        return await this.getJson("/registration/register/check", "POST", undefined, {
            authorization: token,
        });
    }
    async getJson(path, method, body, headers) {
        try {
            const rsp = await fetch(`${this.url}${path}`, {
                method: method,
                body: body ? JSON.stringify(body) : undefined,
                headers: {
                    accept: "application/json",
                    ...(body ? { "content-type": "application/json" } : {}),
                    ...headers,
                },
            });
            const obj = await rsp.json();
            if ("error" in obj) {
                return obj;
            }
            return obj;
        }
        catch (e) {
            console.warn(e);
        }
        return { error: "UNKNOWN_ERROR", errors: [] };
    }
}
