import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { LNURL } from "@snort/shared";
import { ApiHost } from "Const";
import AsyncButton from "Element/AsyncButton";
import useEventPublisher from "Feed/EventPublisher";
import SnortServiceProvider from "Nip05/SnortServiceProvider";
export default function LNForwardAddress({ handle }) {
    const { formatMessage } = useIntl();
    const publisher = useEventPublisher();
    const [newAddress, setNewAddress] = useState(handle.lnAddress ?? "");
    const [error, setError] = useState("");
    async function startUpdate() {
        if (!publisher)
            return;
        const req = {
            lnAddress: newAddress,
        };
        setError("");
        try {
            const svc = new LNURL(newAddress);
            await svc.load();
        }
        catch {
            setError(formatMessage({ id: "0jOEtS", defaultMessage: "Invalid LNURL" }));
            return;
        }
        const sp = new SnortServiceProvider(publisher, `${ApiHost}/api/v1/n5sp`);
        const rsp = await sp.patch(handle.id, req);
        if ("error" in rsp) {
            setError(rsp.error);
        }
    }
    return (_jsxs("div", { className: "card", children: [_jsx("h4", { children: _jsx(FormattedMessage, { id: 'SOqbe9', defaultMessage: 'Update Lightning Address' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'b5vAk0', defaultMessage: 'Your handle will act like a lightning address and will redirect to your chosen LNURL or Lightning address' }) }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "f-grow", children: _jsx("input", { type: "text", className: "w-max mr10", placeholder: formatMessage({ id: "yCLnBC", defaultMessage: "LNURL or Lightning Address" }), value: newAddress, onChange: e => setNewAddress(e.target.value) }) }), _jsx(AsyncButton, { onClick: () => startUpdate(), children: _jsx(FormattedMessage, { id: 'BWpuKl', defaultMessage: 'Update' }) })] }), error && _jsx("b", { className: "error", children: error })] }));
}
