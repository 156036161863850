var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _ZapPool_instances, _ZapPool_store, _ZapPool_isPayoutInProgress, _ZapPool_lastPayout, _ZapPool_key, _ZapPool_save, _ZapPool_load, _ZapPool_autoPayout;
import { UserCache } from "Cache";
import { getDisplayName } from "Element/ProfileImage";
import { LNURL, ExternalStore, unixNow } from "@snort/shared";
import { Toastore } from "Toaster";
import { WalletInvoiceState, Wallets } from "Wallet";
export var ZapPoolRecipientType;
(function (ZapPoolRecipientType) {
    ZapPoolRecipientType[ZapPoolRecipientType["Generic"] = 0] = "Generic";
    ZapPoolRecipientType[ZapPoolRecipientType["Relay"] = 1] = "Relay";
    ZapPoolRecipientType[ZapPoolRecipientType["FileHost"] = 2] = "FileHost";
    ZapPoolRecipientType[ZapPoolRecipientType["DataProvider"] = 3] = "DataProvider";
})(ZapPoolRecipientType || (ZapPoolRecipientType = {}));
class ZapPool extends ExternalStore {
    constructor() {
        super();
        _ZapPool_instances.add(this);
        _ZapPool_store.set(this, new Map());
        _ZapPool_isPayoutInProgress.set(this, false);
        _ZapPool_lastPayout.set(this, 0);
        __classPrivateFieldGet(this, _ZapPool_instances, "m", _ZapPool_load).call(this);
        setTimeout(() => __classPrivateFieldGet(this, _ZapPool_instances, "m", _ZapPool_autoPayout).call(this).catch(console.error), 5000);
    }
    async payout(wallet) {
        if (__classPrivateFieldGet(this, _ZapPool_isPayoutInProgress, "f")) {
            throw new Error("Payout already in progress");
        }
        __classPrivateFieldSet(this, _ZapPool_isPayoutInProgress, true, "f");
        __classPrivateFieldSet(this, _ZapPool_lastPayout, unixNow(), "f");
        for (const x of __classPrivateFieldGet(this, _ZapPool_store, "f").values()) {
            if (x.sum === 0)
                continue;
            try {
                const profile = await UserCache.get(x.pubkey);
                if (!profile) {
                    throw new Error(`Failed to get profile for ${x.pubkey}`);
                }
                const svc = new LNURL(profile.lud16 || profile.lud06 || "");
                await svc.load();
                const amtSend = x.sum;
                const invoice = await svc.getInvoice(amtSend, `SnortZapPool: ${x.split}%`);
                if (invoice.pr) {
                    const result = await wallet.payInvoice(invoice.pr);
                    console.debug("ZPC", invoice, result);
                    if (result.state === WalletInvoiceState.Paid) {
                        x.sum -= amtSend;
                        Toastore.push({
                            element: `Sent ${amtSend.toLocaleString()} sats to ${getDisplayName(profile, x.pubkey)} from your zap pool`,
                            expire: unixNow() + 10,
                            icon: "zap",
                        });
                    }
                    else {
                        throw new Error(`Failed to pay invoice, unknown reason`);
                    }
                }
                else {
                    throw new Error(invoice.reason ?? "Failed to get invoice");
                }
            }
            catch (e) {
                console.error(e);
                if (e instanceof Error) {
                    const profile = UserCache.getFromCache(x.pubkey);
                    Toastore.push({
                        element: `Failed to send sats to ${getDisplayName(profile, x.pubkey)} (${e.message}), please try again later`,
                        expire: unixNow() + 10,
                        icon: "close",
                    });
                }
            }
        }
        __classPrivateFieldGet(this, _ZapPool_instances, "m", _ZapPool_save).call(this);
        this.notifyChange();
        __classPrivateFieldSet(this, _ZapPool_isPayoutInProgress, false, "f");
    }
    calcAllocation(n) {
        let res = 0;
        for (const x of __classPrivateFieldGet(this, _ZapPool_store, "f").values()) {
            res += Math.ceil(n * (x.split / 100));
        }
        return res;
    }
    allocate(n) {
        if (__classPrivateFieldGet(this, _ZapPool_isPayoutInProgress, "f")) {
            throw new Error("Payout is in progress, cannot allocate to pool");
        }
        for (const x of __classPrivateFieldGet(this, _ZapPool_store, "f").values()) {
            x.sum += Math.ceil(n * (x.split / 100));
        }
        __classPrivateFieldGet(this, _ZapPool_instances, "m", _ZapPool_save).call(this);
        this.notifyChange();
    }
    getOrDefault(rcpt) {
        const k = __classPrivateFieldGet(this, _ZapPool_instances, "m", _ZapPool_key).call(this, rcpt);
        if (__classPrivateFieldGet(this, _ZapPool_store, "f").has(k)) {
            return { ...__classPrivateFieldGet(this, _ZapPool_store, "f").get(k) };
        }
        return rcpt;
    }
    set(rcpt) {
        const k = __classPrivateFieldGet(this, _ZapPool_instances, "m", _ZapPool_key).call(this, rcpt);
        // delete entry if split is 0 and sum is 0
        if (rcpt.split === 0 && rcpt.sum === 0 && __classPrivateFieldGet(this, _ZapPool_store, "f").has(k)) {
            __classPrivateFieldGet(this, _ZapPool_store, "f").delete(k);
        }
        else {
            __classPrivateFieldGet(this, _ZapPool_store, "f").set(k, rcpt);
        }
        __classPrivateFieldGet(this, _ZapPool_instances, "m", _ZapPool_save).call(this);
        this.notifyChange();
    }
    takeSnapshot() {
        return [...__classPrivateFieldGet(this, _ZapPool_store, "f").values()];
    }
}
_ZapPool_store = new WeakMap(), _ZapPool_isPayoutInProgress = new WeakMap(), _ZapPool_lastPayout = new WeakMap(), _ZapPool_instances = new WeakSet(), _ZapPool_key = function _ZapPool_key(rcpt) {
    return `${rcpt.pubkey}-${rcpt.type}`;
}, _ZapPool_save = function _ZapPool_save() {
    self.localStorage.setItem("zap-pool", JSON.stringify(this.takeSnapshot()));
    self.localStorage.setItem("zap-pool-last-payout", __classPrivateFieldGet(this, _ZapPool_lastPayout, "f").toString());
}, _ZapPool_load = function _ZapPool_load() {
    const existing = self.localStorage.getItem("zap-pool");
    if (existing) {
        const arr = JSON.parse(existing);
        __classPrivateFieldSet(this, _ZapPool_store, new Map(arr.map(a => [`${a.pubkey}-${a.type}`, a])), "f");
    }
    const lastPayout = self.localStorage.getItem("zap-pool-last-payout");
    if (lastPayout) {
        __classPrivateFieldSet(this, _ZapPool_lastPayout, Number(lastPayout), "f");
    }
}, _ZapPool_autoPayout = async function _ZapPool_autoPayout() {
    const payoutInterval = 60 * 60;
    try {
        if (__classPrivateFieldGet(this, _ZapPool_lastPayout, "f") < unixNow() - payoutInterval) {
            const wallet = Wallets.get();
            if (wallet) {
                if (wallet.canAutoLogin()) {
                    await wallet.login();
                }
                await this.payout(wallet);
            }
        }
    }
    catch (e) {
        console.error(e);
    }
    setTimeout(() => __classPrivateFieldGet(this, _ZapPool_instances, "m", _ZapPool_autoPayout).call(this).catch(console.error), 60000);
};
export const ZapPoolController = new ZapPool();
