import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { BaseUITask } from "Tasks";
export class DonateTask extends BaseUITask {
    constructor() {
        super(...arguments);
        this.id = "donate";
    }
    check() {
        return !this.state.muted;
    }
    render() {
        return (_jsxs(_Fragment, { children: [_jsx("p", { children: _jsx(FormattedMessage, { id: 'IDjHJ6', defaultMessage: 'Thanks for using Snort, please consider donating if you can.' }) }), _jsx(Link, { to: "/donate", children: _jsx("button", { children: _jsx(FormattedMessage, { id: '2IFGap', defaultMessage: 'Donate' }) }) })] }));
    }
}
