import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Timeline from "Element/Timeline";
import useEventPublisher from "Feed/EventPublisher";
import useLogin from "Hooks/useLogin";
import { setTags } from "Login";
import { System } from "index";
const HashTagsPage = () => {
    const params = useParams();
    const tag = (params.tag ?? "").toLowerCase();
    const login = useLogin();
    const isFollowing = useMemo(() => {
        return login.tags.item.includes(tag);
    }, [login, tag]);
    const publisher = useEventPublisher();
    async function followTags(ts) {
        if (publisher) {
            const ev = await publisher.tags(ts);
            System.BroadcastEvent(ev);
            setTags(login, ts, ev.created_at * 1000);
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "main-content", children: _jsxs("div", { className: "action-heading", children: [_jsxs("h2", { children: ["#", tag] }), isFollowing ? (_jsx("button", { type: "button", className: "secondary", onClick: () => followTags(login.tags.item.filter(t => t !== tag)), children: _jsx(FormattedMessage, { id: 'izWS4J', defaultMessage: 'Unfollow' }) })) : (_jsx("button", { type: "button", onClick: () => followTags(login.tags.item.concat([tag])), children: _jsx(FormattedMessage, { id: 'ieGrWo', defaultMessage: 'Follow' }) }))] }) }), _jsx(Timeline, { subject: { type: "hashtag", items: [tag], discriminator: tag }, postsOnly: false, method: "TIME_RANGE" }, tag)] }));
};
export default HashTagsPage;
