import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import useEventPublisher from "Feed/EventPublisher";
import Modal from "Element/Modal";
import { setShow, reset, setSelectedCustomRelays } from "State/ReBroadcast";
import messages from "./messages";
import useLogin from "Hooks/useLogin";
import { System } from "index";
export function ReBroadcaster() {
    const publisher = useEventPublisher();
    const { note, show, selectedCustomRelays } = useSelector((s) => s.reBroadcast);
    const dispatch = useDispatch();
    async function sendReBroadcast() {
        if (note && publisher) {
            if (selectedCustomRelays)
                selectedCustomRelays.forEach(r => System.WriteOnceToRelay(r, note));
            else
                System.BroadcastEvent(note);
            dispatch(reset());
        }
    }
    function cancel() {
        dispatch(reset());
    }
    function onSubmit(ev) {
        ev.stopPropagation();
        sendReBroadcast().catch(console.warn);
    }
    const login = useLogin();
    const relays = login.relays;
    function renderRelayCustomisation() {
        return (_jsx("div", { children: Object.keys(relays.item || {})
                .filter(el => relays.item[el].write)
                .map((r, i, a) => (_jsxs("div", { className: "card flex", children: [_jsx("div", { className: "flex f-col f-grow", children: _jsx("div", { children: r }) }), _jsx("div", { children: _jsx("input", { type: "checkbox", checked: !selectedCustomRelays || selectedCustomRelays.includes(r), onChange: e => dispatch(setSelectedCustomRelays(
                            // set false if all relays selected
                            e.target.checked && selectedCustomRelays && selectedCustomRelays.length == a.length - 1
                                ? false
                                : // otherwise return selectedCustomRelays with target relay added / removed
                                    a.filter(el => el === r ? e.target.checked : !selectedCustomRelays || selectedCustomRelays.includes(el)))) }) })] }))) }));
    }
    return (_jsx(_Fragment, { children: show && (_jsxs(Modal, { className: "note-creator-modal", onClose: () => dispatch(setShow(false)), children: [renderRelayCustomisation(), _jsxs("div", { className: "note-creator-actions", children: [_jsx("button", { className: "secondary", onClick: cancel, children: _jsx(FormattedMessage, { ...messages.Cancel }) }), _jsx("button", { onClick: onSubmit, children: _jsx(FormattedMessage, { ...messages.ReBroadcast }) })] })] })) }));
}
