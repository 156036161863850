import { jsx as _jsx } from "react/jsx-runtime";
import Hls from "hls.js";
import { useEffect, useRef } from "react";
export function LiveVideoPlayer(props) {
    const video = useRef(null);
    useEffect(() => {
        if (props.stream && video.current && !video.current.src && Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(props.stream);
            hls.attachMedia(video.current);
            return () => hls.destroy();
        }
    }, [video, props]);
    return (_jsx("div", { children: _jsx("video", { ref: video, ...props, controls: true }) }));
}
