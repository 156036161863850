import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./Invoice.css";
import { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useMemo } from "react";
import { decodeInvoice } from "@snort/shared";
import SendSats from "Element/SendSats";
import Icon from "Icons/Icon";
import { useWallet } from "Wallet";
import messages from "./messages";
export default function Invoice(props) {
    const invoice = props.invoice;
    const { formatMessage } = useIntl();
    const [showInvoice, setShowInvoice] = useState(false);
    const walletState = useWallet();
    const wallet = walletState.wallet;
    const info = useMemo(() => decodeInvoice(invoice), [invoice]);
    const [isPaid, setIsPaid] = useState(false);
    const isExpired = info?.expired;
    const amount = info?.amount ?? 0;
    const description = info?.description;
    function header() {
        return (_jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(FormattedMessage, { ...messages.Invoice }) }), _jsx(Icon, { name: "zapCircle", className: "zap-circle" }), _jsx(SendSats, { title: formatMessage(messages.PayInvoice), invoice: invoice, show: showInvoice, onClose: () => setShowInvoice(false) })] }));
    }
    async function payInvoice(e) {
        e.stopPropagation();
        if (wallet?.isReady) {
            try {
                await wallet.payInvoice(invoice);
                setIsPaid(true);
            }
            catch (error) {
                setShowInvoice(true);
            }
        }
        else {
            setShowInvoice(true);
        }
    }
    return (_jsx(_Fragment, { children: _jsxs("div", { className: `note-invoice flex ${isExpired ? "expired" : ""} ${isPaid ? "paid" : ""}`, children: [_jsx("div", { className: "invoice-header", children: header() }), _jsx("p", { className: "invoice-amount", children: amount > 0 && (_jsxs(_Fragment, { children: [(amount / 1000).toLocaleString(), " ", _jsxs("span", { className: "sats", children: ["sat", amount === 1000 ? "" : "s"] })] })) }), _jsxs("div", { className: "invoice-body", children: [description && _jsx("p", { children: description }), isPaid ? (_jsx("div", { className: "paid", children: _jsx(FormattedMessage, { ...messages.Paid }) })) : (_jsx("button", { disabled: isExpired, type: "button", onClick: payInvoice, children: isExpired ? _jsx(FormattedMessage, { ...messages.Expired }) : _jsx(FormattedMessage, { ...messages.Pay }) }))] })] }) }));
}
