import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from "Icons/Icon";
import { useState } from "react";
import useFileUpload from "Upload";
import { openFile, unwrap } from "SnortUtils";
export default function AvatarEditor({ picture, onPictureChange }) {
    const uploader = useFileUpload();
    const [error, setError] = useState("");
    async function uploadFile() {
        setError("");
        try {
            const f = await openFile();
            if (f) {
                const rsp = await uploader.upload(f, f.name);
                console.log(rsp);
                if (typeof rsp?.error === "string") {
                    setError(`Upload failed: ${rsp.error}`);
                }
                else {
                    onPictureChange?.(unwrap(rsp.url));
                }
            }
        }
        catch (e) {
            if (e instanceof Error) {
                setError(`Upload failed: ${e.message}`);
            }
            else {
                setError(`Upload failed`);
            }
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "flex f-center", children: _jsx("div", { style: { backgroundImage: `url(${picture})` }, className: "avatar", children: _jsx("div", { className: `edit${picture ? "" : " new"}`, onClick: () => uploadFile().catch(console.error), children: _jsx(Icon, { name: picture ? "edit" : "camera-plus" }) }) }) }), error && _jsx("b", { className: "error", children: error })] }));
}
