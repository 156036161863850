import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { NostrPrefix } from "@snort/system";
import { FormattedMessage } from "react-intl";
import FollowListBase from "Element/FollowListBase";
import PageSpinner from "Element/PageSpinner";
import NostrBandApi from "External/NostrBand";
import SemisolDevApi from "External/SemisolDev";
import useLogin from "Hooks/useLogin";
import { hexToBech32 } from "SnortUtils";
var Provider;
(function (Provider) {
    Provider[Provider["NostrBand"] = 1] = "NostrBand";
    Provider[Provider["SemisolDev"] = 2] = "SemisolDev";
})(Provider || (Provider = {}));
export default function SuggestedProfiles() {
    const login = useLogin();
    const [userList, setUserList] = useState();
    const [provider, setProvider] = useState(Provider.NostrBand);
    const [error, setError] = useState("");
    async function loadSuggestedProfiles() {
        if (!login.publicKey)
            return;
        setUserList(undefined);
        setError("");
        try {
            switch (provider) {
                case Provider.NostrBand: {
                    const api = new NostrBandApi();
                    const users = await api.sugguestedFollows(hexToBech32(NostrPrefix.PublicKey, login.publicKey));
                    const keys = users.profiles.map(a => a.pubkey);
                    setUserList(keys);
                    break;
                }
                case Provider.SemisolDev: {
                    const api = new SemisolDevApi();
                    const users = await api.sugguestedFollows(login.publicKey, login.follows.item);
                    const keys = users.recommendations.sort(a => a[1]).map(a => a[0]);
                    setUserList(keys);
                    break;
                }
            }
        }
        catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            }
        }
    }
    useEffect(() => {
        loadSuggestedProfiles().catch(console.error);
    }, [login, provider]);
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'C8HhVE', defaultMessage: 'Suggested Follows' }) }), _jsxs("div", { className: "card flex f-space", children: [_jsx(FormattedMessage, { id: 'xaj9Ba', defaultMessage: 'Provider' }), _jsxs("select", { onChange: e => setProvider(Number(e.target.value)), children: [_jsx("option", { value: Provider.NostrBand, children: "nostr.band" }), _jsx("option", { value: Provider.SemisolDev, children: "semisol.dev" })] })] }), error && _jsx("b", { className: "error", children: error }), userList ? _jsx(FollowListBase, { pubkeys: userList, showAbout: true }) : _jsx(PageSpinner, {})] }));
}
