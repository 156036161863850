import { jsx as _jsx } from "react/jsx-runtime";
import "./Modal.css";
import { useEffect } from "react";
export default function Modal(props) {
    const onClose = props.onClose || (() => undefined);
    const className = props.className || "";
    useEffect(() => {
        document.body.classList.add("scroll-lock");
        return () => document.body.classList.remove("scroll-lock");
    }, []);
    return (_jsx("div", { className: `modal ${className}`, onClick: onClose, children: _jsx("div", { className: "modal-body", onClick: e => e.stopPropagation(), children: props.children }) }));
}
