import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ApiHost } from "Const";
import AsyncButton from "Element/AsyncButton";
import useEventPublisher from "Feed/EventPublisher";
import SnortServiceProvider from "Nip05/SnortServiceProvider";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
export default function TransferHandle({ handle }) {
    const publisher = useEventPublisher();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [newKey, setNewKey] = useState("");
    const [error, setError] = useState([]);
    async function startTransfer() {
        if (!newKey || !publisher)
            return;
        const sp = new SnortServiceProvider(publisher, `${ApiHost}/api/v1/n5sp`);
        setError([]);
        const rsp = await sp.transfer(handle.id, newKey);
        if ("error" in rsp) {
            setError(rsp.errors);
            return;
        }
        navigate(-1);
    }
    return (_jsxs("div", { className: "card", children: [_jsx("h4", { children: _jsx(FormattedMessage, { id: '5u6iEc', defaultMessage: 'Transfer to Pubkey' }) }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "f-grow", children: _jsx("input", { type: "text", className: "w-max mr10", placeholder: formatMessage({ id: "VR5eHw", defaultMessage: "Public key (npub/nprofile)" }), value: newKey, onChange: e => setNewKey(e.target.value) }) }), _jsx(AsyncButton, { onClick: () => startTransfer(), children: _jsx(FormattedMessage, { id: 'DtYelJ', defaultMessage: 'Transfer' }) })] }), error && _jsx("b", { className: "error", children: error })] }));
}
