var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _NostrBandApi_instances, _NostrBandApi_url, _NostrBandApi_json;
export class NostrBandError extends Error {
    constructor(message, body, status) {
        super(message);
        this.body = body;
        this.statusCode = status;
    }
}
export default class NostrBandApi {
    constructor() {
        _NostrBandApi_instances.add(this);
        _NostrBandApi_url.set(this, "https://api.nostr.band");
    }
    async trendingProfiles() {
        return await __classPrivateFieldGet(this, _NostrBandApi_instances, "m", _NostrBandApi_json).call(this, "GET", "/v0/trending/profiles");
    }
    async trendingNotes() {
        return await __classPrivateFieldGet(this, _NostrBandApi_instances, "m", _NostrBandApi_json).call(this, "GET", "/v0/trending/notes");
    }
    async sugguestedFollows(pubkey) {
        return await __classPrivateFieldGet(this, _NostrBandApi_instances, "m", _NostrBandApi_json).call(this, "GET", `/v0/suggested/profiles/${pubkey}`);
    }
}
_NostrBandApi_url = new WeakMap(), _NostrBandApi_instances = new WeakSet(), _NostrBandApi_json = async function _NostrBandApi_json(method, path) {
    const res = await fetch(`${__classPrivateFieldGet(this, _NostrBandApi_url, "f")}${path}`, {
        method: method ?? "GET",
    });
    if (res.ok) {
        return (await res.json());
    }
    else {
        throw new NostrBandError("Failed to load content from nostr.band", await res.text(), res.status);
    }
};
