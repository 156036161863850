import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useUserProfile } from "@snort/system-react";
import useLogin from "Hooks/useLogin";
import Icon from "Icons/Icon";
import { DonateTask } from "./DonateTask";
import { Nip5Task } from "./Nip5Task";
import { System } from "index";
const AllTasks = [new Nip5Task(), new DonateTask()];
AllTasks.forEach(a => a.load());
export const TaskList = () => {
    const publicKey = useLogin().publicKey;
    const user = useUserProfile(System, publicKey);
    const [, setTick] = useState(0);
    function muteTask(t) {
        t.mute();
        setTick(x => (x += 1));
    }
    return (_jsx(_Fragment, { children: AllTasks.filter(a => (user ? a.check(user) : false)).map(a => {
            return (_jsxs("div", { className: "card", children: [_jsxs("div", { className: "header", children: [_jsx(Icon, { name: "lightbulb" }), _jsx("div", { className: "close", onClick: () => muteTask(a), children: _jsx(Icon, { name: "close", size: 14 }) })] }), a.render()] }, a.id));
        }) }));
};
