import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./RelaysMetadata.css";
import Nostrich from "public/logo_256.png";
import { useState } from "react";
import Icon from "Icons/Icon";
const RelayFavicon = ({ url }) => {
    const cleanUrl = url
        .replace(/^wss:\/\//, "https://")
        .replace(/^ws:\/\//, "http://")
        .replace(/\/$/, "");
    const [faviconUrl, setFaviconUrl] = useState(`${cleanUrl}/favicon.ico`);
    return (_jsx("img", { className: "favicon", src: faviconUrl, onError: () => setFaviconUrl(Nostrich), alt: `favicon for ${url}` }));
};
const RelaysMetadata = ({ relays }) => {
    return (_jsx("div", { className: "main-content", children: relays?.map(({ url, settings }) => {
            return (_jsxs("div", { className: "card relay-card", children: [_jsx(RelayFavicon, { url: url }), _jsx("code", { className: "relay-url f-ellipsis", children: url }), _jsxs("div", { className: "relay-settings", children: [_jsx(Icon, { name: "read", className: settings.read ? "enabled" : "disabled" }), _jsx(Icon, { name: "write", className: settings.write ? "enabled" : "disabled" })] })] }, url));
        }) }));
};
export default RelaysMetadata;
