import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import MuteButton from "Element/MuteButton";
import ProfilePreview from "Element/ProfilePreview";
import useModeration from "Hooks/useModeration";
import messages from "./messages";
export default function MutedList({ pubkeys }) {
    const { isMuted, muteAll } = useModeration();
    const hasAllMuted = pubkeys.every(isMuted);
    return (_jsxs("div", { className: "main-content", children: [_jsxs("div", { className: "flex mt10", children: [_jsx("div", { className: "f-grow bold", children: _jsx(FormattedMessage, { ...messages.MuteCount, values: { n: pubkeys?.length } }) }), _jsx("button", { disabled: hasAllMuted || pubkeys.length === 0, className: "transparent", type: "button", onClick: () => muteAll(pubkeys), children: _jsx(FormattedMessage, { ...messages.MuteAll }) })] }), pubkeys?.map(a => {
                return _jsx(ProfilePreview, { actions: _jsx(MuteButton, { pubkey: a }), pubkey: a, options: { about: false } }, a);
            })] }));
}
