import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import useLogin from "Hooks/useLogin";
import { useUserProfile } from "@snort/system-react";
import { System } from "index";
export default function CashuNuts({ token }) {
    const login = useLogin();
    const profile = useUserProfile(System, login.publicKey);
    async function copyToken(e, token) {
        e.stopPropagation();
        await navigator.clipboard.writeText(token);
    }
    async function redeemToken(e, token) {
        e.stopPropagation();
        const lnurl = profile?.lud16 ?? "";
        const url = `https://redeem.cashu.me?token=${encodeURIComponent(token)}&lightning=${encodeURIComponent(lnurl)}&autopay=yes`;
        window.open(url, "_blank");
    }
    const [cashu, setCashu] = useState();
    useEffect(() => {
        try {
            if (!token.startsWith("cashuA") || token.length < 10) {
                return;
            }
            import("@cashu/cashu-ts").then(({ getDecodedToken }) => {
                const tkn = getDecodedToken(token);
                setCashu(tkn);
            });
        }
        catch {
            // ignored
        }
    }, [token]);
    if (!cashu)
        return _jsx(_Fragment, { children: token });
    return (_jsx("div", { className: "note-invoice", children: _jsxs("div", { className: "flex f-between", children: [_jsxs("div", { children: [_jsx("h4", { children: _jsx(FormattedMessage, { id: 'TMfYfY', defaultMessage: 'Cashu token' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'ULotH9', defaultMessage: 'Amount: {amount} sats', values: {
                                    amount: cashu.token[0].proofs.reduce((acc, v) => acc + v.amount, 0),
                                } }) }), _jsx("small", { className: "xs", children: _jsx(FormattedMessage, { id: 'iUsU2x', defaultMessage: 'Mint: {url}', values: { url: cashu.token[0].mint } }) })] }), _jsxs("div", { children: [_jsx("button", { onClick: e => copyToken(e, token), className: "mr5", children: _jsx(FormattedMessage, { id: 'SX58hM', defaultMessage: 'Copy' }) }), _jsx("button", { onClick: e => redeemToken(e, token), children: _jsx(FormattedMessage, { id: 'XrSk2j', defaultMessage: 'Redeem' }) })] })] }) }));
}
