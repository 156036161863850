var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _ToasterSlots_instances, _ToasterSlots_stack, _ToasterSlots_cleanup, _ToasterSlots_eatToast;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSyncExternalStore } from "react";
import { v4 as uuid } from "uuid";
import { ExternalStore, unixNow } from "@snort/shared";
import Icon from "Icons/Icon";
import "./Toaster.css";
class ToasterSlots extends ExternalStore {
    constructor() {
        super(...arguments);
        _ToasterSlots_instances.add(this);
        _ToasterSlots_stack.set(this, []);
        _ToasterSlots_cleanup.set(this, setInterval(() => __classPrivateFieldGet(this, _ToasterSlots_instances, "m", _ToasterSlots_eatToast).call(this), 1000));
    }
    push(n) {
        n.expire ?? (n.expire = unixNow() + 3);
        n.id ?? (n.id = uuid());
        __classPrivateFieldGet(this, _ToasterSlots_stack, "f").push(n);
        this.notifyChange();
    }
    takeSnapshot() {
        return [...__classPrivateFieldGet(this, _ToasterSlots_stack, "f")];
    }
}
_ToasterSlots_stack = new WeakMap(), _ToasterSlots_cleanup = new WeakMap(), _ToasterSlots_instances = new WeakSet(), _ToasterSlots_eatToast = function _ToasterSlots_eatToast() {
    const now = unixNow();
    __classPrivateFieldSet(this, _ToasterSlots_stack, __classPrivateFieldGet(this, _ToasterSlots_stack, "f").filter(a => (a.expire ?? 0) > now), "f");
    this.notifyChange();
};
export const Toastore = new ToasterSlots();
export default function Toaster() {
    const toast = useSyncExternalStore(c => Toastore.hook(c), () => Toastore.snapshot());
    return (_jsx("div", { className: "toaster", children: toast.map(a => (_jsxs("div", { className: "card flex", children: [_jsx(Icon, { name: a.icon ?? "bell", className: "mr5" }), a.element] }, a.id))) }));
}
