import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import SuggestedProfiles from "Element/SuggestedProfiles";
import { TabElement } from "Element/Tabs";
import TrendingNotes from "Element/TrendingPosts";
import TrendingUsers from "Element/TrendingUsers";
import { useState } from "react";
import { useIntl } from "react-intl";
export default function Discover() {
    const { formatMessage } = useIntl();
    // tabs
    const Tabs = {
        Follows: { text: formatMessage({ id: "C8HhVE", defaultMessage: "Suggested Follows" }), value: 0 },
        Posts: { text: formatMessage({ id: "Ix8l+B", defaultMessage: "Trending Notes" }), value: 1 },
        Profiles: { text: formatMessage({ id: "CVWeJ6", defaultMessage: "Trending People" }), value: 2 },
    };
    const [tab, setTab] = useState(Tabs.Follows);
    function renderTab() {
        switch (tab.value) {
            case 0:
                return _jsx(SuggestedProfiles, {});
            case 1:
                return _jsx(TrendingNotes, {});
            case 2:
                return _jsx(TrendingUsers, {});
        }
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "tabs", children: [Tabs.Follows, Tabs.Posts, Tabs.Profiles].map(a => (_jsx(TabElement, { tab: tab, setTab: setTab, t: a }, a.value))) }), renderTab()] }));
}
