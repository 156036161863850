import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ApiHost } from "Const";
import Logo from "Element/Logo";
import AsyncButton from "Element/AsyncButton";
import FollowListBase from "Element/FollowListBase";
import { bech32ToHex } from "SnortUtils";
import SnortApi from "SnortApi";
import useLogin from "Hooks/useLogin";
import messages from "./messages";
export default function ImportFollows() {
    const navigate = useNavigate();
    const currentFollows = useLogin().follows;
    const { formatMessage } = useIntl();
    const [twitterUsername, setTwitterUsername] = useState("");
    const [follows, setFollows] = useState([]);
    const [error, setError] = useState("");
    const api = new SnortApi(ApiHost);
    const sortedTwitterFollows = useMemo(() => {
        return follows.map(a => bech32ToHex(a)).sort(a => (currentFollows.item.includes(a) ? 1 : -1));
    }, [follows, currentFollows]);
    async function loadFollows() {
        setFollows([]);
        setError("");
        try {
            const rsp = await api.twitterImport(twitterUsername);
            if (Array.isArray(rsp) && rsp.length === 0) {
                setError(formatMessage(messages.NoUsersFound, { twitterUsername }));
            }
            else {
                setFollows(rsp);
            }
        }
        catch (e) {
            console.warn(e);
            if (e instanceof Error) {
                setError(e.message);
            }
            else {
                setError(formatMessage(messages.FailedToLoad));
            }
        }
    }
    return (_jsxs("div", { className: "main-content new-user", dir: "auto", children: [_jsx(Logo, {}), _jsx("div", { className: "progress-bar", children: _jsx("div", { className: "progress progress-last" }) }), _jsx("h1", { children: _jsx(FormattedMessage, { ...messages.ImportTwitter }) }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.FindYourFollows, values: {
                        provider: (_jsx("a", { href: "https://nostr.directory", target: "_blank", rel: "noreferrer", children: "nostr.directory" })),
                    } }) }), _jsx("div", { className: "next-actions continue-actions", children: _jsx("button", { type: "button", onClick: () => navigate("/new/discover"), children: _jsx(FormattedMessage, { ...messages.Next }) }) }), _jsx("h2", { children: _jsx(FormattedMessage, { ...messages.TwitterUsername }) }), _jsxs("div", { className: "flex", children: [_jsx("input", { type: "text", placeholder: formatMessage(messages.TwitterPlaceholder), className: "f-grow mr10", value: twitterUsername, onChange: e => setTwitterUsername(e.target.value) }), _jsx(AsyncButton, { type: "button", className: "secondary tall", onClick: loadFollows, children: _jsx(FormattedMessage, { ...messages.Check }) })] }), error.length > 0 && _jsx("b", { className: "error", children: error }), _jsx("div", { dir: "ltr", children: sortedTwitterFollows.length > 0 && (_jsx(FollowListBase, { title: _jsx("h2", { children: _jsx(FormattedMessage, { ...messages.FollowsOnNostr, values: { username: twitterUsername } }) }), pubkeys: sortedTwitterFollows })) })] }));
}
