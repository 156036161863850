import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate, Link } from "react-router-dom";
import { useUserProfile } from "@snort/system-react";
import { profileLink } from "SnortUtils";
import { System } from "index";
export default function Username({ pubkey, onLinkVisit }) {
    const user = useUserProfile(System, pubkey);
    const navigate = useNavigate();
    function onClick(ev) {
        ev.preventDefault();
        onLinkVisit();
        navigate(profileLink(pubkey));
    }
    return user ? (_jsx(Link, { to: profileLink(pubkey), onClick: onClick, children: user.name || pubkey.slice(0, 12) })) : null;
}
