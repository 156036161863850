import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { FileExtensionRegex, YoutubeUrlRegex, TweetUrlRegex, TidalRegex, SoundCloudRegex, MixCloudRegex, SpotifyRegex, TwitchRegex, AppleMusicRegex, NostrNestsRegex, WavlakeRegex, } from "Const";
import { magnetURIDecode } from "SnortUtils";
import SoundCloudEmbed from "Element/SoundCloudEmded";
import MixCloudEmbed from "Element/MixCloudEmbed";
import SpotifyEmbed from "Element/SpotifyEmbed";
import TidalEmbed from "Element/TidalEmbed";
import TwitchEmbed from "Element/TwitchEmbed";
import AppleMusicEmbed from "Element/AppleMusicEmbed";
import WavlakeEmbed from "Element/WavlakeEmbed";
import LinkPreview from "Element/LinkPreview";
import NostrLink from "Element/NostrLink";
import RevealMedia from "Element/RevealMedia";
import MagnetLink from "Element/MagnetLink";
export default function HyperText({ link, creator, depth, disableMediaSpotlight }) {
    const a = link;
    try {
        const url = new URL(a);
        const youtubeId = YoutubeUrlRegex.test(a) && RegExp.$1;
        const tweetId = TweetUrlRegex.test(a) && RegExp.$2;
        const tidalId = TidalRegex.test(a) && RegExp.$1;
        const soundcloundId = SoundCloudRegex.test(a) && RegExp.$1;
        const mixcloudId = MixCloudRegex.test(a) && RegExp.$1;
        const isSpotifyLink = SpotifyRegex.test(a);
        const isTwitchLink = TwitchRegex.test(a);
        const isAppleMusicLink = AppleMusicRegex.test(a);
        const isNostrNestsLink = NostrNestsRegex.test(a);
        const isWavlakeLink = WavlakeRegex.test(a);
        const extension = FileExtensionRegex.test(url.pathname.toLowerCase()) && RegExp.$1;
        if (extension && !isAppleMusicLink) {
            return _jsx(RevealMedia, { link: a, creator: creator, disableSpotlight: disableMediaSpotlight });
        }
        else if (tweetId) {
            return (_jsx("div", { className: "tweet", children: _jsx(TwitterTweetEmbed, { tweetId: tweetId }) }, tweetId));
        }
        else if (youtubeId) {
            return (_jsx("iframe", { className: "w-max", src: `https://www.youtube.com/embed/${youtubeId}`, title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", allowFullScreen: true }, youtubeId));
        }
        else if (tidalId) {
            return _jsx(TidalEmbed, { link: a });
        }
        else if (soundcloundId) {
            return _jsx(SoundCloudEmbed, { link: a });
        }
        else if (mixcloudId) {
            return _jsx(MixCloudEmbed, { link: a });
        }
        else if (isSpotifyLink) {
            return _jsx(SpotifyEmbed, { link: a });
        }
        else if (isTwitchLink) {
            return _jsx(TwitchEmbed, { link: a });
        }
        else if (isAppleMusicLink) {
            return _jsx(AppleMusicEmbed, { link: a });
        }
        else if (isNostrNestsLink) {
            return (_jsx(_Fragment, { children: _jsx("a", { href: a, onClick: e => e.stopPropagation(), target: "_blank", rel: "noreferrer", className: "ext", children: a }) }));
        }
        else if (isWavlakeLink) {
            return _jsx(WavlakeEmbed, { link: a });
        }
        else if (url.protocol === "nostr:" || url.protocol === "web+nostr:") {
            return _jsx(NostrLink, { link: a, depth: depth });
        }
        else if (url.protocol === "magnet:") {
            const parsed = magnetURIDecode(a);
            if (parsed) {
                return _jsx(MagnetLink, { magnet: parsed });
            }
        }
        else {
            return _jsx(LinkPreview, { url: a });
        }
    }
    catch {
        // Ignore the error.
    }
    return (_jsx("a", { href: a, onClick: e => e.stopPropagation(), target: "_blank", rel: "noreferrer", className: "ext", children: a }));
}
