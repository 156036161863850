import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import FollowListBase from "Element/FollowListBase";
import PageSpinner from "Element/PageSpinner";
import NostrBandApi from "External/NostrBand";
export default function TrendingUsers() {
    const [userList, setUserList] = useState();
    async function loadTrendingUsers() {
        const api = new NostrBandApi();
        const users = await api.trendingProfiles();
        const keys = users.profiles.map(a => a.pubkey);
        setUserList(keys);
    }
    useEffect(() => {
        loadTrendingUsers().catch(console.error);
    }, []);
    if (!userList)
        return _jsx(PageSpinner, {});
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'CVWeJ6', defaultMessage: 'Trending People' }) }), _jsx(FollowListBase, { pubkeys: userList, showAbout: true })] }));
}
