import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { v4 as uuid } from "uuid";
import AsyncButton from "Element/AsyncButton";
import { unwrap } from "SnortUtils";
import LNDHubWallet from "Wallet/LNDHub";
import { WalletKind, Wallets } from "Wallet";
import { useNavigate } from "react-router-dom";
const ConnectLNDHub = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [config, setConfig] = useState();
    const [error, setError] = useState();
    async function tryConnect(config) {
        try {
            const connection = new LNDHubWallet(config);
            await connection.login();
            const info = await connection.getInfo();
            const newWallet = {
                id: uuid(),
                kind: WalletKind.LNDHub,
                active: true,
                info,
                data: config,
            };
            Wallets.add(newWallet);
            navigate("/wallet");
        }
        catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            }
            else {
                setError(formatMessage({ id: "qDwvZ4", defaultMessage: "Unknown error" }));
            }
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(FormattedMessage, { id: 'KAhAcM', defaultMessage: 'Enter LNDHub config' }) }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "f-grow mr10", children: _jsx("input", { type: "text", placeholder: "lndhub://username:password@lndhub.io", className: "w-max", value: config, onChange: e => setConfig(e.target.value) }) }), _jsx(AsyncButton, { onClick: () => tryConnect(unwrap(config)), disabled: !config, children: _jsx(FormattedMessage, { id: '+vVZ/G', defaultMessage: 'Connect' }) })] }), error && _jsx("b", { className: "error p10", children: error })] }));
};
export default ConnectLNDHub;
