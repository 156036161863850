var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _SnortApi_instances, _SnortApi_url, _SnortApi_publisher, _SnortApi_getJsonAuthd, _SnortApi_getJson;
import { EventKind } from "@snort/system";
import { ApiHost } from "Const";
export var SubscriptionErrorCode;
(function (SubscriptionErrorCode) {
    SubscriptionErrorCode[SubscriptionErrorCode["InternalError"] = 1] = "InternalError";
    SubscriptionErrorCode[SubscriptionErrorCode["SubscriptionActive"] = 2] = "SubscriptionActive";
    SubscriptionErrorCode[SubscriptionErrorCode["Duplicate"] = 3] = "Duplicate";
})(SubscriptionErrorCode || (SubscriptionErrorCode = {}));
export class SubscriptionError extends Error {
    constructor(msg, code) {
        super(msg);
        this.code = code;
    }
}
export default class SnortApi {
    constructor(url, publisher) {
        _SnortApi_instances.add(this);
        _SnortApi_url.set(this, void 0);
        _SnortApi_publisher.set(this, void 0);
        __classPrivateFieldSet(this, _SnortApi_url, new URL(url ?? ApiHost).toString(), "f");
        __classPrivateFieldSet(this, _SnortApi_publisher, publisher, "f");
    }
    revenueSplits() {
        return __classPrivateFieldGet(this, _SnortApi_instances, "m", _SnortApi_getJson).call(this, "api/v1/revenue/splits");
    }
    revenueToday() {
        return __classPrivateFieldGet(this, _SnortApi_instances, "m", _SnortApi_getJson).call(this, "api/v1/revenue/today");
    }
    twitterImport(username) {
        return __classPrivateFieldGet(this, _SnortApi_instances, "m", _SnortApi_getJson).call(this, `api/v1/twitter/follows-for-nostr?username=${encodeURIComponent(username)}`);
    }
    createSubscription(type) {
        return __classPrivateFieldGet(this, _SnortApi_instances, "m", _SnortApi_getJsonAuthd).call(this, `api/v1/subscription?type=${type}`, "PUT");
    }
    renewSubscription(id) {
        return __classPrivateFieldGet(this, _SnortApi_instances, "m", _SnortApi_getJsonAuthd).call(this, `api/v1/subscription/${id}/renew`, "GET");
    }
    listSubscriptions() {
        return __classPrivateFieldGet(this, _SnortApi_instances, "m", _SnortApi_getJsonAuthd).call(this, "api/v1/subscription");
    }
    linkPreview(url) {
        return __classPrivateFieldGet(this, _SnortApi_instances, "m", _SnortApi_getJson).call(this, `api/v1/preview?url=${encodeURIComponent(url)}`);
    }
}
_SnortApi_url = new WeakMap(), _SnortApi_publisher = new WeakMap(), _SnortApi_instances = new WeakSet(), _SnortApi_getJsonAuthd = async function _SnortApi_getJsonAuthd(path, method, body, headers) {
    if (!__classPrivateFieldGet(this, _SnortApi_publisher, "f")) {
        throw new Error("Publisher not set");
    }
    const auth = await __classPrivateFieldGet(this, _SnortApi_publisher, "f").generic(eb => {
        return eb
            .kind(EventKind.HttpAuthentication)
            .tag(["url", `${__classPrivateFieldGet(this, _SnortApi_url, "f")}${path}`])
            .tag(["method", method ?? "GET"]);
    });
    return __classPrivateFieldGet(this, _SnortApi_instances, "m", _SnortApi_getJson).call(this, path, method, body, {
        ...headers,
        authorization: `Nostr ${window.btoa(JSON.stringify(auth))}`,
    });
}, _SnortApi_getJson = async function _SnortApi_getJson(path, method, body, headers) {
    const rsp = await fetch(`${__classPrivateFieldGet(this, _SnortApi_url, "f")}${path}`, {
        method: method,
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            accept: "application/json",
            ...(body ? { "content-type": "application/json" } : {}),
            ...headers,
        },
    });
    const obj = await rsp.json();
    if ("error" in obj) {
        throw new SubscriptionError(obj.error, obj.code);
    }
    return obj;
};
