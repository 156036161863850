import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./BadgeList.css";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ProxyImg } from "Element/ProxyImg";
import Icon from "Icons/Icon";
import Modal from "Element/Modal";
import Username from "Element/Username";
import { findTag } from "SnortUtils";
export default function BadgeList({ badges }) {
    const [showModal, setShowModal] = useState(false);
    const badgeMetadata = badges.map(b => {
        const thumb = findTag(b, "thumb");
        const image = findTag(b, "image");
        const name = findTag(b, "name");
        const description = findTag(b, "description");
        return {
            id: b.id,
            pubkey: b.pubkey,
            name,
            description,
            thumb: thumb?.length ?? 0 > 0 ? thumb : image,
            image,
        };
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "badge-list", onClick: () => setShowModal(!showModal), children: badgeMetadata.slice(0, 8).map(({ id, name, thumb }) => (_jsx(ProxyImg, { alt: name, className: "badge-item", size: 64, src: thumb }, id))) }), showModal && (_jsx(Modal, { className: "reactions-modal", onClose: () => setShowModal(false), children: _jsxs("div", { className: "reactions-view", children: [_jsx("div", { className: "close", onClick: () => setShowModal(false), children: _jsx(Icon, { name: "close" }) }), _jsx("div", { className: "reactions-header", children: _jsx("h2", { children: _jsx(FormattedMessage, { id: 'h8XMJL', defaultMessage: 'Badges' }) }) }), _jsx("div", { className: "body", children: badgeMetadata.map(({ id, name, pubkey, description, image }) => {
                                return (_jsxs("div", { className: "reactions-item badges-item", children: [_jsx(ProxyImg, { className: "reaction-icon", src: image, size: 64, alt: name }), _jsxs("div", { className: "badge-info", children: [_jsx("h3", { children: name }), _jsx("p", { children: description }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'RfhLwC', defaultMessage: 'By: {author}', values: { author: _jsx(Username, { pubkey: pubkey, onLinkVisit: () => setShowModal(false) }) } }) })] })] }, id));
                            }) })] }) }))] }));
}
