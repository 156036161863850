import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./Keys.css";
import { FormattedMessage } from "react-intl";
import { encodeTLV, NostrPrefix } from "@snort/system";
import Copy from "Element/Copy";
import useLogin from "Hooks/useLogin";
import { hexToMnemonic } from "nip6";
import { hexToBech32 } from "SnortUtils";
export default function ExportKeys() {
    const { publicKey, privateKey, generatedEntropy } = useLogin();
    return (_jsxs("div", { className: "export-keys", children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'bep9C3', defaultMessage: 'Public Key' }) }), _jsx(Copy, { text: hexToBech32("npub", publicKey ?? ""), maxSize: 48, className: "mb10" }), _jsx(Copy, { text: encodeTLV(NostrPrefix.Profile, publicKey ?? ""), maxSize: 48 }), privateKey && (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'JymXbw', defaultMessage: 'Private Key' }) }), _jsx(Copy, { text: hexToBech32("nsec", privateKey), maxSize: 48 })] })), generatedEntropy && (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'b12Goz', defaultMessage: 'Mnemonic' }) }), _jsx(Copy, { text: hexToMnemonic(generatedEntropy ?? ""), maxSize: 48 })] }))] }));
}
