import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./Relay.css";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import useRelayState from "Feed/RelayState";
import { System } from "index";
import { getRelayName, unixNowMs, unwrap } from "SnortUtils";
import useLogin from "Hooks/useLogin";
import { setRelays } from "Login";
import Icon from "Icons/Icon";
import messages from "./messages";
export default function Relay(props) {
    const navigate = useNavigate();
    const login = useLogin();
    const relaySettings = unwrap(login.relays.item[props.addr] ?? System.Sockets.find(a => a.address === props.addr)?.settings ?? {});
    const state = useRelayState(props.addr);
    const name = useMemo(() => getRelayName(props.addr), [props.addr]);
    function configure(o) {
        setRelays(login, {
            ...login.relays.item,
            [props.addr]: o,
        }, unixNowMs());
    }
    return (_jsx(_Fragment, { children: _jsxs("div", { className: `relay w-max`, children: [_jsx("div", { className: `flex ${state?.connected ? "bg-success" : "bg-error"}`, children: _jsx(Icon, { name: "wifi" }) }), _jsxs("div", { className: "f-grow f-col", children: [_jsxs("div", { className: "flex mb10", children: [_jsx("b", { className: "f-2", children: name }), _jsxs("div", { className: "f-1", children: [_jsx(FormattedMessage, { ...messages.Write }), _jsx("span", { className: "checkmark", onClick: () => configure({
                                                write: !relaySettings.write,
                                                read: relaySettings.read,
                                            }), children: _jsx(Icon, { name: relaySettings.write ? "check" : "close", size: 12 }) })] }), _jsxs("div", { className: "f-1", children: [_jsx(FormattedMessage, { ...messages.Read }), _jsx("span", { className: "checkmark", onClick: () => configure({
                                                write: relaySettings.write,
                                                read: !relaySettings.read,
                                            }), children: _jsx(Icon, { name: relaySettings.read ? "check" : "close", size: 12 }) })] })] }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "f-grow" }), _jsx("div", { children: _jsx("span", { className: "icon-btn", onClick: () => navigate(state?.id ?? ""), children: _jsx(Icon, { name: "gear", size: 12 }) }) })] })] })] }) }));
}
