import { jsx as _jsx } from "react/jsx-runtime";
import "./ShowMore.css";
import { useIntl } from "react-intl";
import messages from "./messages";
const ShowMore = ({ text, onClick, className = "" }) => {
    const { formatMessage } = useIntl();
    const defaultText = formatMessage(messages.ShowMore);
    const classNames = className ? `show-more ${className}` : "show-more";
    return (_jsx("div", { className: "show-more-container", children: _jsx("button", { className: classNames, onClick: onClick, children: text || defaultText }) }));
};
export default ShowMore;
