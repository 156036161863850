import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate, Link } from "react-router-dom";
import { RecommendedFollows } from "Const";
import Logo from "Element/Logo";
import FollowListBase from "Element/FollowListBase";
import { clearEntropy } from "Login";
import useLogin from "Hooks/useLogin";
import TrendingUsers from "Element/TrendingUsers";
import messages from "./messages";
export default function DiscoverFollows() {
    const { formatMessage } = useIntl();
    const login = useLogin();
    const navigate = useNavigate();
    const sortedReccomends = useMemo(() => {
        return RecommendedFollows.sort(() => (Math.random() >= 0.5 ? -1 : 1)).map(a => a.toLowerCase());
    }, []);
    async function clearEntropyAndGo() {
        clearEntropy(login);
        navigate("/");
    }
    return (_jsxs("div", { className: "main-content new-user", dir: "auto", children: [_jsx(Logo, {}), _jsx("div", { className: "progress-bar", children: _jsx("div", { className: "progress" }) }), _jsx("h1", { children: _jsx(FormattedMessage, { ...messages.Ready }) }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.Share, values: { link: _jsx(Link, { to: "/", children: formatMessage(messages.World) }) } }) }), _jsx("div", { className: "next-actions continue-actions", children: _jsxs("button", { type: "button", onClick: () => clearEntropyAndGo(), children: [_jsx(FormattedMessage, { ...messages.Done }), " "] }) }), _jsx("h3", { children: _jsx(FormattedMessage, { ...messages.PopularAccounts }) }), sortedReccomends.length > 0 && _jsx(FollowListBase, { pubkeys: sortedReccomends, showAbout: true }), _jsx(TrendingUsers, {})] }));
}
