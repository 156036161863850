import { jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useUserProfile } from "@snort/system-react";
import { profileLink } from "SnortUtils";
import { getDisplayName } from "Element/ProfileImage";
import { System } from "index";
export default function Mention({ pubkey, relays }) {
    const user = useUserProfile(System, pubkey);
    const name = useMemo(() => {
        return getDisplayName(user, pubkey);
    }, [user, pubkey]);
    return (_jsxs(Link, { to: profileLink(pubkey, relays), onClick: e => e.stopPropagation(), children: ["@", name] }));
}
