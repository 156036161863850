var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _SnortServiceProvider_publisher;
import { EventKind } from "@snort/system";
import { ServiceProvider } from "./ServiceProvider";
export var ForwardType;
(function (ForwardType) {
    ForwardType[ForwardType["Redirect"] = 0] = "Redirect";
    ForwardType[ForwardType["ProxyDirect"] = 1] = "ProxyDirect";
    ForwardType[ForwardType["ProxyTrusted"] = 2] = "ProxyTrusted";
})(ForwardType || (ForwardType = {}));
export default class SnortServiceProvider extends ServiceProvider {
    constructor(publisher, url) {
        super(url);
        _SnortServiceProvider_publisher.set(this, void 0);
        __classPrivateFieldSet(this, _SnortServiceProvider_publisher, publisher, "f");
    }
    async list() {
        return this.getJsonAuthd("/list", "GET");
    }
    async transfer(id, to) {
        return this.getJsonAuthd(`/${id}/transfer?to=${to}`, "PATCH");
    }
    async patch(id, obj) {
        return this.getJsonAuthd(`/${id}`, "PATCH", obj);
    }
    async registerForSubscription(handle, domain, id) {
        return this.getJsonAuthd(`/registration/register/${id}`, "PUT", {
            name: handle,
            domain,
            pk: "",
            ref: "snort",
        });
    }
    async getJsonAuthd(path, method, body, headers) {
        const auth = await __classPrivateFieldGet(this, _SnortServiceProvider_publisher, "f").generic(eb => {
            eb.kind(EventKind.HttpAuthentication);
            eb.tag(["url", `${this.url}${path}`]);
            eb.tag(["method", method ?? "GET"]);
            return eb;
        });
        if (!auth) {
            return {
                error: "INVALID_TOKEN",
            };
        }
        return this.getJson(path, method, body, {
            ...headers,
            authorization: `Nostr ${window.btoa(JSON.stringify(auth))}`,
        });
    }
}
_SnortServiceProvider_publisher = new WeakMap();
