import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Preferences.css";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import useLogin from "Hooks/useLogin";
import { DefaultPreferences, updatePreferences } from "Login";
import { DefaultImgProxy } from "Const";
import { unwrap } from "SnortUtils";
import messages from "./messages";
export const AllLanguageCodes = [
    "en",
    "ja",
    "es",
    "hu",
    "zh-CN",
    "zh-TW",
    "fr",
    "ar",
    "it",
    "id",
    "de",
    "ru",
    "sv",
    "hr",
    "ta-IN",
    "fa-IR",
];
const PreferencesPage = () => {
    const { formatMessage } = useIntl();
    const login = useLogin();
    const perf = login.preferences;
    const [emoji, setEmoji] = useState([]);
    useEffect(() => {
        (async () => {
            const emoji = await import("@jukben/emoji-search");
            setEmoji(emoji.default("").map(a => ({ name: a.name, char: a.char })));
        })();
    }, []);
    return (_jsxs("div", { className: "preferences", children: [_jsx("h3", { children: _jsx(FormattedMessage, { ...messages.Preferences }) }), _jsxs("div", { className: "card flex", children: [_jsx("div", { className: "flex f-col f-grow", children: _jsx("div", { children: _jsx(FormattedMessage, { id: 'y1Z3or', defaultMessage: 'Language' }) }) }), _jsx("div", { children: _jsx("select", { value: perf.language || DefaultPreferences.language, onChange: e => updatePreferences(login, {
                                ...perf,
                                language: e.target.value,
                            }), style: { textTransform: "capitalize" }, children: AllLanguageCodes.sort().map(a => (_jsx("option", { value: a, children: new Intl.DisplayNames([a], {
                                    type: "language",
                                }).of(a) }))) }) })] }), _jsxs("div", { className: "card flex", children: [_jsx("div", { className: "flex f-col f-grow", children: _jsx("div", { children: _jsx(FormattedMessage, { ...messages.Theme }) }) }), _jsx("div", { children: _jsxs("select", { value: perf.theme, onChange: e => updatePreferences(login, {
                                ...perf,
                                theme: e.target.value,
                            }), children: [_jsx("option", { value: "system", children: _jsx(FormattedMessage, { ...messages.System }) }), _jsx("option", { value: "light", children: _jsx(FormattedMessage, { ...messages.Light }) }), _jsx("option", { value: "dark", children: _jsx(FormattedMessage, { ...messages.Dark }) })] }) })] }), _jsxs("div", { className: "card flex", children: [_jsx("div", { className: "flex f-col f-grow", children: _jsx("div", { children: _jsx(FormattedMessage, { ...messages.DefaultRootTab }) }) }), _jsx("div", { children: _jsxs("select", { value: perf.defaultRootTab, onChange: e => updatePreferences(login, {
                                ...perf,
                                defaultRootTab: e.target.value,
                            }), children: [_jsx("option", { value: "notes", children: _jsx(FormattedMessage, { id: '7+Domh', defaultMessage: 'Notes' }) }), _jsx("option", { value: "conversations", children: _jsx(FormattedMessage, { ...messages.Conversations }) }), _jsx("option", { value: "global", children: _jsx(FormattedMessage, { ...messages.Global }) })] }) })] }), _jsx("div", { className: "card flex", children: _jsxs("div", { className: "flex f-col f-grow", children: [_jsx("div", { children: _jsx(FormattedMessage, { ...messages.AutoloadMedia }) }), _jsx("small", { children: _jsx(FormattedMessage, { ...messages.AutoloadMediaHelp }) }), _jsx("div", { className: "mt10", children: _jsxs("select", { value: perf.autoLoadMedia, onChange: e => updatePreferences(login, {
                                    ...perf,
                                    autoLoadMedia: e.target.value,
                                }), children: [_jsx("option", { value: "none", children: _jsx(FormattedMessage, { ...messages.None }) }), _jsx("option", { value: "follows-only", children: _jsx(FormattedMessage, { ...messages.FollowsOnly }) }), _jsx("option", { value: "all", children: _jsx(FormattedMessage, { ...messages.All }) })] }) })] }) }), _jsxs("div", { className: "card flex", children: [_jsx("div", { className: "flex f-col f-grow", children: _jsx("div", { children: _jsx(FormattedMessage, { id: 'qMx1sA', defaultMessage: 'Default Zap amount' }) }) }), _jsx("div", { children: _jsx("input", { type: "number", defaultValue: perf.defaultZapAmount, min: 1, onChange: e => updatePreferences(login, { ...perf, defaultZapAmount: parseInt(e.target.value || "0") }) }) })] }), _jsxs("div", { className: "card flex", children: [_jsxs("div", { className: "flex f-col f-grow", children: [_jsx("div", { children: _jsx(FormattedMessage, { id: 'Dh3hbq', defaultMessage: 'Auto Zap' }) }), _jsx("small", { children: _jsx(FormattedMessage, { id: 'D+KzKd', defaultMessage: 'Automatically zap every note when loaded' }) })] }), _jsx("div", { children: _jsx("input", { type: "checkbox", checked: perf.autoZap, onChange: e => updatePreferences(login, { ...perf, autoZap: e.target.checked }) }) })] }), _jsxs("div", { className: "card flex f-col", children: [_jsxs("div", { className: "flex w-max", children: [_jsxs("div", { className: "flex f-col f-grow", children: [_jsx("div", { children: _jsx(FormattedMessage, { ...messages.ImgProxy }) }), _jsx("small", { children: _jsx(FormattedMessage, { ...messages.ImgProxyHelp }) })] }), _jsx("div", { children: _jsx("input", { type: "checkbox", checked: perf.imgProxyConfig !== null, onChange: e => updatePreferences(login, {
                                        ...perf,
                                        imgProxyConfig: e.target.checked ? DefaultImgProxy : null,
                                    }) }) })] }), perf.imgProxyConfig && (_jsxs("div", { className: "w-max mt10 form", children: [_jsxs("div", { className: "form-group", children: [_jsx("div", { children: _jsx(FormattedMessage, { ...messages.ServiceUrl }) }), _jsx("div", { className: "w-max", children: _jsx("input", { type: "text", value: perf.imgProxyConfig?.url, placeholder: formatMessage({ id: "cQfLWb", defaultMessage: "URL.." }), onChange: e => updatePreferences(login, {
                                                ...perf,
                                                imgProxyConfig: {
                                                    ...unwrap(perf.imgProxyConfig),
                                                    url: e.target.value,
                                                },
                                            }) }) })] }), _jsxs("div", { className: "form-group", children: [_jsx("div", { children: _jsx(FormattedMessage, { ...messages.ServiceKey }) }), _jsx("div", { className: "w-max", children: _jsx("input", { type: "password", value: perf.imgProxyConfig?.key, placeholder: formatMessage({ id: "H+vHiz", defaultMessage: "Hex Key.." }), onChange: e => updatePreferences(login, {
                                                ...perf,
                                                imgProxyConfig: {
                                                    ...unwrap(perf.imgProxyConfig),
                                                    key: e.target.value,
                                                },
                                            }) }) })] }), _jsxs("div", { className: "form-group", children: [_jsx("div", { children: _jsx(FormattedMessage, { ...messages.ServiceSalt }) }), _jsx("div", { className: "w-max", children: _jsx("input", { type: "password", value: perf.imgProxyConfig?.salt, placeholder: formatMessage({ id: "TpgeGw", defaultMessage: "Hex Salt.." }), onChange: e => updatePreferences(login, {
                                                ...perf,
                                                imgProxyConfig: {
                                                    ...unwrap(perf.imgProxyConfig),
                                                    salt: e.target.value,
                                                },
                                            }) }) })] })] }))] }), _jsxs("div", { className: "card flex", children: [_jsxs("div", { className: "flex f-col f-grow", children: [_jsx("div", { children: _jsx(FormattedMessage, { ...messages.EnableReactions }) }), _jsx("small", { children: _jsx(FormattedMessage, { ...messages.EnableReactionsHelp }) })] }), _jsx("div", { children: _jsx("input", { type: "checkbox", checked: perf.enableReactions, onChange: e => updatePreferences(login, { ...perf, enableReactions: e.target.checked }) }) })] }), _jsx("div", { className: "card flex", children: _jsxs("div", { className: "flex f-col f-grow", children: [_jsx("div", { children: _jsx(FormattedMessage, { ...messages.ReactionEmoji }) }), _jsx("small", { children: _jsx(FormattedMessage, { ...messages.ReactionEmojiHelp }) }), _jsx("div", { className: "mt10", children: _jsxs("select", { className: "emoji-selector", value: perf.reactionEmoji, onChange: e => updatePreferences(login, {
                                    ...perf,
                                    reactionEmoji: e.target.value,
                                }), children: [_jsxs("option", { value: "+", children: ["+ ", _jsx(FormattedMessage, { ...messages.Default })] }), emoji.map(({ name, char }) => {
                                        return (_jsxs("option", { value: char, children: [name, " ", char] }));
                                    })] }) })] }) }), _jsxs("div", { className: "card flex", children: [_jsxs("div", { className: "flex f-col f-grow", children: [_jsx("div", { children: _jsx(FormattedMessage, { ...messages.ConfirmReposts }) }), _jsx("small", { children: _jsx(FormattedMessage, { ...messages.ConfirmRepostsHelp }) })] }), _jsx("div", { children: _jsx("input", { type: "checkbox", checked: perf.confirmReposts, onChange: e => updatePreferences(login, { ...perf, confirmReposts: e.target.checked }) }) })] }), _jsxs("div", { className: "card flex", children: [_jsxs("div", { className: "flex f-col f-grow", children: [_jsx("div", { children: _jsx(FormattedMessage, { ...messages.ShowLatest }) }), _jsx("small", { children: _jsx(FormattedMessage, { ...messages.ShowLatestHelp }) })] }), _jsx("div", { children: _jsx("input", { type: "checkbox", checked: perf.autoShowLatest, onChange: e => updatePreferences(login, { ...perf, autoShowLatest: e.target.checked }) }) })] }), _jsx("div", { className: "card flex", children: _jsxs("div", { className: "flex f-col f-grow", children: [_jsx("div", { children: _jsx(FormattedMessage, { ...messages.FileUpload }) }), _jsx("small", { children: _jsx(FormattedMessage, { ...messages.FileUploadHelp }) }), _jsx("div", { className: "mt10", children: _jsxs("select", { value: perf.fileUploader, onChange: e => updatePreferences(login, {
                                    ...perf,
                                    fileUploader: e.target.value,
                                }), children: [_jsxs("option", { value: "void.cat", children: ["void.cat ", _jsx(FormattedMessage, { ...messages.Default })] }), _jsx("option", { value: "nostr.build", children: "nostr.build" }), _jsx("option", { value: "nostrimg.com", children: "nostrimg.com" })] }) })] }) }), _jsxs("div", { className: "card flex", children: [_jsxs("div", { className: "flex f-col f-grow", children: [_jsx("div", { children: _jsx(FormattedMessage, { ...messages.DebugMenus }) }), _jsx("small", { children: _jsx(FormattedMessage, { ...messages.DebugMenusHelp }) })] }), _jsx("div", { children: _jsx("input", { type: "checkbox", checked: perf.showDebugMenus, onChange: e => updatePreferences(login, { ...perf, showDebugMenus: e.target.checked }) }) })] })] }));
};
export default PreferencesPage;
