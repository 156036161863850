import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./AsyncButton.css";
import { useState } from "react";
import Spinner from "../Icons/Spinner";
export default function AsyncButton(props) {
    const [loading, setLoading] = useState(false);
    async function handle(e) {
        e.stopPropagation();
        if (loading || props.disabled)
            return;
        setLoading(true);
        try {
            if (typeof props.onClick === "function") {
                const f = props.onClick(e);
                if (f instanceof Promise) {
                    await f;
                }
            }
        }
        finally {
            setLoading(false);
        }
    }
    return (_jsxs("button", { className: "spinner-button", type: "button", disabled: loading || props.disabled, ...props, onClick: handle, children: [_jsx("span", { style: { visibility: loading ? "hidden" : "visible" }, children: props.children }), loading && (_jsx("span", { className: "spinner-wrapper", children: _jsx(Spinner, {}) }))] }));
}
