import { jsx as _jsx } from "react/jsx-runtime";
import BlockButton from "Element/BlockButton";
import ProfilePreview from "Element/ProfilePreview";
import useModeration from "Hooks/useModeration";
export default function BlockList() {
    const { blocked } = useModeration();
    return (_jsx("div", { className: "main-content", children: blocked.map(a => {
            return _jsx(ProfilePreview, { actions: _jsx(BlockButton, { pubkey: a }), pubkey: a, options: { about: false } }, a);
        }) }));
}
