import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { v4 as uuid } from "uuid";
import AsyncButton from "Element/AsyncButton";
import { unwrap } from "SnortUtils";
import { WalletKind, Wallets } from "Wallet";
import { useNavigate } from "react-router-dom";
const ConnectCashu = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [mintUrl, setMintUrl] = useState();
    const [error, setError] = useState();
    async function tryConnect(config) {
        try {
            if (!mintUrl) {
                throw new Error("Mint URL is required");
            }
            const { CashuWallet } = await import("Wallet/Cashu");
            const connection = new CashuWallet(config);
            await connection.login();
            const info = await connection.getInfo();
            const newWallet = {
                id: uuid(),
                kind: WalletKind.Cashu,
                active: true,
                info,
                data: mintUrl,
            };
            Wallets.add(newWallet);
            navigate("/wallet");
        }
        catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            }
            else {
                setError(formatMessage({ id: "qDwvZ4", defaultMessage: "Unknown error" }));
            }
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(FormattedMessage, { id: 'KoFlZg', defaultMessage: 'Enter mint URL' }) }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "f-grow mr10", children: _jsx("input", { type: "text", placeholder: "Mint URL", className: "w-max", value: mintUrl, onChange: e => setMintUrl(e.target.value) }) }), _jsx(AsyncButton, { onClick: () => tryConnect(unwrap(mintUrl)), disabled: !mintUrl, children: _jsx(FormattedMessage, { id: '+vVZ/G', defaultMessage: 'Connect' }) })] }), error && _jsx("b", { className: "error p10", children: error })] }));
};
export default ConnectCashu;
