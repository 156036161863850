import { jsx as _jsx } from "react/jsx-runtime";
import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef } from "react";
export default function QrCode(props) {
    const qrRef = useRef(null);
    useEffect(() => {
        if ((props.data?.length ?? 0) > 0 && qrRef.current) {
            const qr = new QRCodeStyling({
                width: props.width || 256,
                height: props.height || 256,
                data: props.data,
                margin: 5,
                type: "canvas",
                image: props.avatar,
                dotsOptions: {
                    type: "rounded",
                },
                cornersSquareOptions: {
                    type: "extra-rounded",
                },
                imageOptions: {
                    crossOrigin: "anonymous",
                },
            });
            qrRef.current.innerHTML = "";
            qr.append(qrRef.current);
            if (props.link) {
                qrRef.current.onclick = function () {
                    const elm = document.createElement("a");
                    elm.href = props.link ?? "";
                    elm.click();
                };
            }
        }
        else if (qrRef.current) {
            qrRef.current.innerHTML = "";
        }
    }, [props.data, props.link]);
    return _jsx("div", { className: `qr${props.className ?? ""}`, ref: qrRef });
}
