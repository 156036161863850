import { FeedCache } from "@snort/shared";
import { db } from "Db";
export class ChatCache extends FeedCache {
    constructor() {
        super("ChatCache", db.chats);
    }
    key(of) {
        return of.id;
    }
    async preload() {
        await super.preload();
        // load all dms to memory
        await this.buffer([...this.onTable]);
    }
    newest() {
        let ret = 0;
        this.cache.forEach(v => (ret = v.created_at > ret ? v.created_at : ret));
        return ret;
    }
    takeSnapshot() {
        return [...this.cache.values()];
    }
}
