import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./ZapstrEmbed.css";
import { Link } from "react-router-dom";
import { encodeTLV, NostrPrefix } from "@snort/system";
import { ProxyImg } from "Element/ProxyImg";
import ProfileImage from "Element/ProfileImage";
import { FormattedMessage } from "react-intl";
export default function ZapstrEmbed({ ev }) {
    const media = ev.tags.find(a => a[0] === "media");
    const cover = ev.tags.find(a => a[0] === "cover");
    const subject = ev.tags.find(a => a[0] === "subject");
    const refPersons = ev.tags.filter(a => a[0] === "p");
    const link = encodeTLV(NostrPrefix.Address, ev.tags.find(a => a[0] === "d")?.[1] ?? "", undefined, ev.kind, ev.pubkey);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex zapstr mb10 card", children: [_jsx(ProxyImg, { src: cover?.[1] ?? "", size: 100 }), _jsxs("div", { className: "flex f-col", children: [_jsx("div", { children: _jsx("h3", { children: subject?.[1] ?? "" }) }), _jsx("audio", { src: media?.[1] ?? "", controls: true }), _jsx("div", { children: refPersons.map(a => (_jsx(ProfileImage, { pubkey: a[1], subHeader: _jsx(_Fragment, { children: a[2] ?? "" }), className: "", defaultNip: " " }))) })] })] }), _jsx(Link, { to: `https://zapstr.live/?track=${link}`, target: "_blank", children: _jsx("button", { children: _jsx(FormattedMessage, { id: 'Lu5/Bj', defaultMessage: 'Open on Zapstr' }) }) })] }));
}
