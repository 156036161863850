import { unixNow } from "SnortUtils";
export var SubscriptionType;
(function (SubscriptionType) {
    SubscriptionType[SubscriptionType["Supporter"] = 0] = "Supporter";
    SubscriptionType[SubscriptionType["Premium"] = 1] = "Premium";
})(SubscriptionType || (SubscriptionType = {}));
export var LockedFeatures;
(function (LockedFeatures) {
    LockedFeatures[LockedFeatures["MultiAccount"] = 1] = "MultiAccount";
    LockedFeatures[LockedFeatures["NostrAddress"] = 2] = "NostrAddress";
    LockedFeatures[LockedFeatures["Badge"] = 3] = "Badge";
    LockedFeatures[LockedFeatures["DeepL"] = 4] = "DeepL";
    LockedFeatures[LockedFeatures["RelayRetention"] = 5] = "RelayRetention";
    LockedFeatures[LockedFeatures["RelayBackup"] = 6] = "RelayBackup";
    LockedFeatures[LockedFeatures["RelayAccess"] = 7] = "RelayAccess";
    LockedFeatures[LockedFeatures["LNProxy"] = 8] = "LNProxy";
    LockedFeatures[LockedFeatures["EmailBridge"] = 9] = "EmailBridge";
})(LockedFeatures || (LockedFeatures = {}));
export const Plans = [
    {
        id: SubscriptionType.Supporter,
        price: 5000,
        disabled: false,
        unlocks: [
            LockedFeatures.MultiAccount,
            LockedFeatures.NostrAddress,
            LockedFeatures.Badge,
            LockedFeatures.RelayAccess,
        ],
    },
    {
        id: SubscriptionType.Premium,
        price: 20000,
        disabled: false,
        unlocks: [
            LockedFeatures.DeepL,
            LockedFeatures.RelayBackup,
            LockedFeatures.RelayRetention,
            LockedFeatures.LNProxy,
            LockedFeatures.EmailBridge,
        ],
    },
];
export function getActiveSubscriptions(s) {
    const now = unixNow();
    return [...s].sort((a, b) => b.type - a.type).filter(a => a.start <= now && a.end > now);
}
export function getCurrentSubscription(s) {
    return getActiveSubscriptions(s)[0];
}
