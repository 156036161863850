import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { BaseUITask } from "Tasks";
export class Nip5Task extends BaseUITask {
    constructor() {
        super(...arguments);
        this.id = "buy-nip5";
    }
    check(user) {
        return !this.state.muted && !user.nip05;
    }
    render() {
        return (_jsx("p", { children: _jsx(FormattedMessage, { id: 'wvFw6Y', defaultMessage: 'Hey, it looks like you dont have a NIP-05 handle yet, you should get one! Check out {link}', values: {
                    link: (_jsx(Link, { to: "/verification", children: _jsx(FormattedMessage, { id: 'Iwm6o2', defaultMessage: 'NIP-05 Shop' }) })),
                } }) }));
    }
}
