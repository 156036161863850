import { jsx as _jsx } from "react/jsx-runtime";
import { NostrPrefix, tryParseNostrLink } from "@snort/system";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "Icons/Spinner";
import { profileLink } from "SnortUtils";
import { getNip05PubKey } from "Pages/LoginPage";
export default function NostrLinkHandler() {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const link = decodeURIComponent(params["*"] ?? "").toLowerCase();
    async function handleLink(link) {
        const nav = tryParseNostrLink(link);
        if (nav) {
            if (nav.type === NostrPrefix.Event || nav.type === NostrPrefix.Note || nav.type === NostrPrefix.Address) {
                navigate(`/e/${nav.encode()}`);
            }
            else if (nav.type === NostrPrefix.PublicKey || nav.type === NostrPrefix.Profile) {
                navigate(`/p/${nav.encode()}`);
            }
        }
        else {
            try {
                const pubkey = await getNip05PubKey(`${link}@snort.social`);
                if (pubkey) {
                    navigate(profileLink(pubkey));
                }
            }
            catch {
                //ignored
            }
        }
        setLoading(false);
    }
    useEffect(() => {
        if (link.length > 0) {
            handleLink(link).catch(console.error);
        }
    }, [link]);
    return (_jsx("div", { className: "flex f-center", children: loading ? (_jsx(Spinner, { width: 50, height: 50 })) : (_jsx("b", { className: "error", children: _jsx(FormattedMessage, { id: 'oJ+JJN', defaultMessage: 'Nothing found :/' }) })) }));
}
