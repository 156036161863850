import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { NostrPrefix, tryParseNostrLink } from "@snort/system";
import Mention from "Element/Mention";
import NoteQuote from "Element/NoteQuote";
export default function NostrLink({ link, depth }) {
    const nav = tryParseNostrLink(link);
    if (nav?.type === NostrPrefix.PublicKey || nav?.type === NostrPrefix.Profile) {
        return _jsx(Mention, { pubkey: nav.id, relays: nav.relays });
    }
    else if (nav?.type === NostrPrefix.Note || nav?.type === NostrPrefix.Event || nav?.type === NostrPrefix.Address) {
        if ((depth ?? 0) > 0) {
            const evLink = nav.encode();
            return (_jsxs(Link, { to: `/e/${evLink}`, onClick: e => e.stopPropagation(), state: { from: location.pathname }, children: ["#", evLink.substring(0, 12)] }));
        }
        else {
            return _jsx(NoteQuote, { link: nav, depth: depth });
        }
    }
    else {
        return (_jsx("a", { href: link, onClick: e => e.stopPropagation(), target: "_blank", rel: "noreferrer", className: "ext", children: link }));
    }
}
