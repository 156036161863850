import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { Outlet, useNavigate } from "react-router-dom";
import ListHandles from "./ListHandles";
import ManageHandleIndex from "./Manage";
export default function ManageHandlePage() {
    const navigate = useNavigate();
    return (_jsxs(_Fragment, { children: [_jsx("h3", { onClick: () => navigate("/settings/handle"), className: "pointer", children: _jsx(FormattedMessage, { id: '9pMqYs', defaultMessage: 'Nostr Address' }) }), _jsx(Outlet, {})] }));
}
export const ManageHandleRoutes = [
    {
        path: "/settings/handle",
        element: _jsx(ManageHandlePage, {}),
        children: [
            {
                path: "",
                element: _jsx(ListHandles, {}),
            },
            {
                path: "manage",
                element: _jsx(ManageHandleIndex, {}),
            },
        ],
    },
];
