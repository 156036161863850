var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _MultiAccountStore_instances, _MultiAccountStore_activeAccount, _MultiAccountStore_accounts, _MultiAccountStore_createPublisher, _MultiAccountStore_migrate, _MultiAccountStore_save;
import * as secp from "@noble/curves/secp256k1";
import * as utils from "@noble/curves/abstract/utils";
import { EventPublisher, Nip46Signer, Nip7Signer, PrivateKeySigner } from "@snort/system";
import { deepClone, sanitizeRelayUrl, unwrap, ExternalStore } from "@snort/shared";
import { DefaultRelays } from "Const";
import { LoginSessionType } from "Login";
import { DefaultPreferences } from "./Preferences";
const AccountStoreKey = "sessions";
const LoggedOut = {
    type: "public_key",
    preferences: DefaultPreferences,
    tags: {
        item: [],
        timestamp: 0,
    },
    follows: {
        item: [],
        timestamp: 0,
    },
    muted: {
        item: [],
        timestamp: 0,
    },
    blocked: {
        item: [],
        timestamp: 0,
    },
    bookmarked: {
        item: [],
        timestamp: 0,
    },
    pinned: {
        item: [],
        timestamp: 0,
    },
    relays: {
        item: Object.fromEntries([...DefaultRelays.entries()].map(a => [unwrap(sanitizeRelayUrl(a[0])), a[1]])),
        timestamp: 0,
    },
    latestNotification: 0,
    readNotifications: 0,
    subscriptions: [],
};
const LegacyKeys = {
    PrivateKeyItem: "secret",
    PublicKeyItem: "pubkey",
    NotificationsReadItem: "notifications-read",
    UserPreferencesKey: "preferences",
    RelayListKey: "last-relays",
    FollowList: "last-follows",
};
export class MultiAccountStore extends ExternalStore {
    constructor() {
        super();
        _MultiAccountStore_instances.add(this);
        _MultiAccountStore_activeAccount.set(this, void 0);
        _MultiAccountStore_accounts.set(this, void 0);
        const existing = window.localStorage.getItem(AccountStoreKey);
        if (existing) {
            const logins = JSON.parse(existing);
            __classPrivateFieldSet(this, _MultiAccountStore_accounts, new Map(logins.map(a => [unwrap(a.publicKey), a])), "f");
        }
        else {
            __classPrivateFieldSet(this, _MultiAccountStore_accounts, new Map(), "f");
        }
        __classPrivateFieldGet(this, _MultiAccountStore_instances, "m", _MultiAccountStore_migrate).call(this);
        if (!__classPrivateFieldGet(this, _MultiAccountStore_activeAccount, "f")) {
            __classPrivateFieldSet(this, _MultiAccountStore_activeAccount, __classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").keys().next().value, "f");
        }
        for (const [, v] of __classPrivateFieldGet(this, _MultiAccountStore_accounts, "f")) {
            v.publisher = __classPrivateFieldGet(this, _MultiAccountStore_instances, "m", _MultiAccountStore_createPublisher).call(this, v);
        }
    }
    getSessions() {
        return [...__classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").keys()];
    }
    allSubscriptions() {
        return [...__classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").values()].map(a => a.subscriptions).flat();
    }
    switchAccount(pk) {
        if (__classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").has(pk)) {
            __classPrivateFieldSet(this, _MultiAccountStore_activeAccount, pk, "f");
            __classPrivateFieldGet(this, _MultiAccountStore_instances, "m", _MultiAccountStore_save).call(this);
        }
    }
    loginWithPubkey(key, type, relays, remoteSignerRelays, privateKey) {
        if (__classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").has(key)) {
            throw new Error("Already logged in with this pubkey");
        }
        const initRelays = this.decideInitRelays(relays);
        const newSession = {
            ...LoggedOut,
            type,
            publicKey: key,
            relays: {
                item: initRelays,
                timestamp: 1,
            },
            preferences: deepClone(DefaultPreferences),
            remoteSignerRelays,
            privateKey,
        };
        newSession.publisher = __classPrivateFieldGet(this, _MultiAccountStore_instances, "m", _MultiAccountStore_createPublisher).call(this, newSession);
        __classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").set(key, newSession);
        __classPrivateFieldSet(this, _MultiAccountStore_activeAccount, key, "f");
        __classPrivateFieldGet(this, _MultiAccountStore_instances, "m", _MultiAccountStore_save).call(this);
        return newSession;
    }
    decideInitRelays(relays) {
        if (relays && Object.keys(relays).length > 0) {
            return relays;
        }
        return Object.fromEntries(DefaultRelays.entries());
    }
    loginWithPrivateKey(key, entropy, relays) {
        const pubKey = utils.bytesToHex(secp.schnorr.getPublicKey(key));
        if (__classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").has(pubKey)) {
            throw new Error("Already logged in with this pubkey");
        }
        const initRelays = relays ?? Object.fromEntries(DefaultRelays.entries());
        const newSession = {
            ...LoggedOut,
            type: LoginSessionType.PrivateKey,
            privateKey: key,
            publicKey: pubKey,
            generatedEntropy: entropy,
            relays: {
                item: initRelays,
                timestamp: 1,
            },
            preferences: deepClone(DefaultPreferences),
        };
        newSession.publisher = __classPrivateFieldGet(this, _MultiAccountStore_instances, "m", _MultiAccountStore_createPublisher).call(this, newSession);
        __classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").set(pubKey, newSession);
        __classPrivateFieldSet(this, _MultiAccountStore_activeAccount, pubKey, "f");
        __classPrivateFieldGet(this, _MultiAccountStore_instances, "m", _MultiAccountStore_save).call(this);
        return newSession;
    }
    updateSession(s) {
        const pk = unwrap(s.publicKey);
        if (__classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").has(pk)) {
            __classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").set(pk, s);
            __classPrivateFieldGet(this, _MultiAccountStore_instances, "m", _MultiAccountStore_save).call(this);
        }
    }
    removeSession(k) {
        if (__classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").delete(k)) {
            if (__classPrivateFieldGet(this, _MultiAccountStore_activeAccount, "f") === k) {
                __classPrivateFieldSet(this, _MultiAccountStore_activeAccount, undefined, "f");
            }
            __classPrivateFieldGet(this, _MultiAccountStore_instances, "m", _MultiAccountStore_save).call(this);
        }
    }
    takeSnapshot() {
        const s = __classPrivateFieldGet(this, _MultiAccountStore_activeAccount, "f") ? __classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").get(__classPrivateFieldGet(this, _MultiAccountStore_activeAccount, "f")) : undefined;
        if (!s)
            return LoggedOut;
        return s;
    }
}
_MultiAccountStore_activeAccount = new WeakMap(), _MultiAccountStore_accounts = new WeakMap(), _MultiAccountStore_instances = new WeakSet(), _MultiAccountStore_createPublisher = function _MultiAccountStore_createPublisher(l) {
    switch (l.type) {
        case LoginSessionType.PrivateKey: {
            return EventPublisher.privateKey(unwrap(l.privateKey));
        }
        case LoginSessionType.Nip46: {
            const relayArgs = (l.remoteSignerRelays ?? []).map(a => `relay=${encodeURIComponent(a)}`);
            const inner = new PrivateKeySigner(unwrap(l.privateKey));
            const nip46 = new Nip46Signer(`bunker://${unwrap(l.publicKey)}?${[...relayArgs].join("&")}`, inner);
            return new EventPublisher(nip46, unwrap(l.publicKey));
        }
        default: {
            if (l.publicKey) {
                return new EventPublisher(new Nip7Signer(), l.publicKey);
            }
        }
    }
}, _MultiAccountStore_migrate = function _MultiAccountStore_migrate() {
    let didMigrate = false;
    const oldPreferences = window.localStorage.getItem(LegacyKeys.UserPreferencesKey);
    const pref = oldPreferences ? JSON.parse(oldPreferences) : deepClone(DefaultPreferences);
    window.localStorage.removeItem(LegacyKeys.UserPreferencesKey);
    const privKey = window.localStorage.getItem(LegacyKeys.PrivateKeyItem);
    if (privKey) {
        const pubKey = utils.bytesToHex(secp.schnorr.getPublicKey(privKey));
        __classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").set(pubKey, {
            ...LoggedOut,
            privateKey: privKey,
            publicKey: pubKey,
            preferences: pref,
        });
        window.localStorage.removeItem(LegacyKeys.PrivateKeyItem);
        window.localStorage.removeItem(LegacyKeys.PublicKeyItem);
        didMigrate = true;
    }
    const pubKey = window.localStorage.getItem(LegacyKeys.PublicKeyItem);
    if (pubKey) {
        __classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").set(pubKey, {
            ...LoggedOut,
            publicKey: pubKey,
            preferences: pref,
        });
        window.localStorage.removeItem(LegacyKeys.PublicKeyItem);
        didMigrate = true;
    }
    window.localStorage.removeItem(LegacyKeys.RelayListKey);
    window.localStorage.removeItem(LegacyKeys.FollowList);
    window.localStorage.removeItem(LegacyKeys.NotificationsReadItem);
    // replace default tab with notes
    for (const [, v] of __classPrivateFieldGet(this, _MultiAccountStore_accounts, "f")) {
        if (v.preferences.defaultRootTab === "posts") {
            v.preferences.defaultRootTab = "notes";
            didMigrate = true;
        }
    }
    // update session types
    for (const [, v] of __classPrivateFieldGet(this, _MultiAccountStore_accounts, "f")) {
        if (!v.type) {
            v.type = v.privateKey ? LoginSessionType.PrivateKey : LoginSessionType.Nip7;
            didMigrate = true;
        }
    }
    if (didMigrate) {
        console.debug("Finished migration to MultiAccountStore");
        __classPrivateFieldGet(this, _MultiAccountStore_instances, "m", _MultiAccountStore_save).call(this);
    }
}, _MultiAccountStore_save = function _MultiAccountStore_save() {
    if (!__classPrivateFieldGet(this, _MultiAccountStore_activeAccount, "f") && __classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").size > 0) {
        __classPrivateFieldSet(this, _MultiAccountStore_activeAccount, [...__classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").keys()][0], "f");
    }
    window.localStorage.setItem(AccountStoreKey, JSON.stringify([...__classPrivateFieldGet(this, _MultiAccountStore_accounts, "f").values()]));
    this.notifyChange();
};
