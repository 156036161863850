import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Zap.css";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { unwrap } from "SnortUtils";
import { formatShort } from "Number";
import Text from "Element/Text";
import ProfileImage from "Element/ProfileImage";
import useLogin from "Hooks/useLogin";
import messages from "./messages";
const Zap = ({ zap, showZapped = true }) => {
    const { amount, content, sender, valid, receiver } = zap;
    const pubKey = useLogin().publicKey;
    return valid && sender ? (_jsxs("div", { className: "zap note card", children: [_jsxs("div", { className: "header", children: [_jsx(ProfileImage, { pubkey: sender }), receiver !== pubKey && showZapped && _jsx(ProfileImage, { pubkey: unwrap(receiver) }), _jsx("div", { className: "amount", children: _jsx("span", { className: "amount-number", children: _jsx(FormattedMessage, { ...messages.Sats, values: { n: formatShort(amount ?? 0) } }) }) })] }), (content?.length ?? 0) > 0 && sender && (_jsx("div", { className: "body", children: _jsx(Text, { creator: sender, content: unwrap(content), tags: [] }) }))] })) : null;
};
export const ZapsSummary = ({ zaps }) => {
    const { formatMessage } = useIntl();
    const sortedZaps = useMemo(() => {
        const pub = [...zaps.filter(z => z.sender && z.valid)];
        const priv = [...zaps.filter(z => !z.sender && z.valid)];
        pub.sort((a, b) => b.amount - a.amount);
        return pub.concat(priv);
    }, [zaps]);
    if (zaps.length === 0) {
        return null;
    }
    const [topZap, ...restZaps] = sortedZaps;
    const { sender, amount, anonZap } = topZap;
    return (_jsx("div", { className: "zaps-summary", children: amount && (_jsx("div", { className: `top-zap`, children: _jsxs("div", { className: "summary", children: [sender && (_jsx(ProfileImage, { pubkey: anonZap ? "" : sender, overrideUsername: anonZap ? formatMessage({ id: "LXxsbk", defaultMessage: "Anonymous" }) : undefined })), restZaps.length > 0 ? (_jsx(FormattedMessage, { ...messages.Others, values: { n: restZaps.length } })) : (_jsx(FormattedMessage, { ...messages.Zapped })), " ", _jsx(FormattedMessage, { ...messages.OthersZapped, values: { n: restZaps.length } })] }) })) }));
};
export default Zap;
