import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { Outlet, useNavigate } from "react-router-dom";
import SettingsIndex from "Pages/settings/Index";
import Profile from "Pages/settings/Profile";
import Relay from "Pages/settings/Relays";
import Preferences from "Pages/settings/Preferences";
import RelayInfo from "Pages/settings/RelayInfo";
import AccountsPage from "Pages/settings/Accounts";
import { WalletSettingsRoutes } from "Pages/settings/WalletSettings";
import { ManageHandleRoutes } from "Pages/settings/handle";
import ExportKeys from "Pages/settings/Keys";
import messages from "./messages";
export default function SettingsPage() {
    const navigate = useNavigate();
    return (_jsxs("div", { className: "main-content", children: [_jsx("h2", { onClick: () => navigate("/settings"), className: "pointer", children: _jsx(FormattedMessage, { ...messages.Settings }) }), _jsx(Outlet, {})] }));
}
export const SettingsRoutes = [
    {
        path: "",
        element: _jsx(SettingsIndex, {}),
    },
    {
        path: "profile",
        element: _jsx(Profile, {}),
    },
    {
        path: "relays",
        element: _jsx(Relay, {}),
    },
    {
        path: "relays/:id",
        element: _jsx(RelayInfo, {}),
    },
    {
        path: "preferences",
        element: _jsx(Preferences, {}),
    },
    {
        path: "accounts",
        element: _jsx(AccountsPage, {}),
    },
    {
        path: "keys",
        element: _jsx(ExportKeys, {}),
    },
    ...ManageHandleRoutes,
    ...WalletSettingsRoutes,
];
