import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { randomSample, unixNowMs } from "SnortUtils";
import Relay from "Element/Relay";
import useEventPublisher from "Feed/EventPublisher";
import { System } from "index";
import useLogin from "Hooks/useLogin";
import { setRelays } from "Login";
import messages from "./messages";
const RelaySettingsPage = () => {
    const publisher = useEventPublisher();
    const login = useLogin();
    const relays = login.relays;
    const [newRelay, setNewRelay] = useState();
    const otherConnections = useMemo(() => {
        return System.Sockets.filter(a => relays.item[a.address] === undefined);
    }, [relays]);
    async function saveRelays() {
        if (publisher) {
            const ev = await publisher.contactList(login.follows.item, login.relays.item);
            System.BroadcastEvent(ev);
            try {
                const onlineRelays = await fetch("https://api.nostr.watch/v1/online").then(r => r.json());
                const relayList = await publisher.relayList(login.relays.item);
                const rs = Object.keys(relays.item).concat(randomSample(onlineRelays, 20));
                rs.forEach(r => {
                    System.WriteOnceToRelay(r, relayList);
                });
            }
            catch (error) {
                console.error(error);
            }
        }
    }
    const handleNewRelayChange = (event) => {
        const inputValue = event.target.value;
        const protocol = window.location.protocol;
        if ((protocol === "https:" && inputValue.startsWith("wss://")) || protocol === "http:") {
            setNewRelay(inputValue);
        }
    };
    function addRelay() {
        return (_jsxs(_Fragment, { children: [_jsx("h4", { children: _jsx(FormattedMessage, { ...messages.AddRelays }) }), _jsx("div", { className: "flex mb10", children: _jsx("input", { type: "text", className: "f-grow", placeholder: "wss://my-relay.com", value: newRelay, onChange: handleNewRelayChange }) }), _jsx("button", { className: "secondary mb10", onClick: () => addNewRelay(), children: _jsx(FormattedMessage, { ...messages.Add }) })] }));
    }
    function addNewRelay() {
        if ((newRelay?.length ?? 0) > 0) {
            const parsed = new URL(newRelay ?? "");
            const payload = {
                ...relays.item,
                [parsed.toString()]: { read: true, write: true },
            };
            setRelays(login, payload, unixNowMs());
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { ...messages.Relays }) }), _jsx("div", { className: "flex f-col mb10", children: Object.keys(relays.item || {}).map(a => (_jsx(Relay, { addr: a }, a))) }), _jsxs("div", { className: "flex mt10", children: [_jsx("div", { className: "f-grow" }), _jsx("button", { type: "button", onClick: () => saveRelays(), children: _jsx(FormattedMessage, { ...messages.Save }) })] }), addRelay(), _jsx("h3", { children: _jsx(FormattedMessage, { id: 'LF5kYT', defaultMessage: 'Other Connections' }) }), _jsx("div", { className: "flex f-col mb10", children: otherConnections.map(a => (_jsx(Relay, { addr: a.address }, a.id))) })] }));
};
export default RelaySettingsPage;
