import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { ApiHost } from "Const";
import useEventPublisher from "Feed/EventPublisher";
import SnortServiceProvider from "Nip05/SnortServiceProvider";
export default function ListHandles() {
    const navigate = useNavigate();
    const publisher = useEventPublisher();
    const [handles, setHandles] = useState([]);
    useEffect(() => {
        loadHandles().catch(console.error);
    }, [publisher]);
    async function loadHandles() {
        if (!publisher)
            return;
        const sp = new SnortServiceProvider(publisher, `${ApiHost}/api/v1/n5sp`);
        const list = await sp.list();
        setHandles(list);
    }
    return (_jsxs(_Fragment, { children: [handles.length === 0 && (_jsx(FormattedMessage, { id: 'mErPop', defaultMessage: 'It looks like you dont have any, check {link} to buy one!', values: {
                    link: (_jsx(Link, { to: "/verification", children: _jsx(FormattedMessage, { id: 'FpxElY', defaultMessage: 'Verification' }) })),
                } })), handles.map(a => (_jsxs("div", { className: "card flex", children: [_jsx("div", { className: "f-grow", children: _jsxs("h4", { className: "nip05", children: [a.handle, "@", _jsx("span", { className: "domain", "data-domain": a.domain?.toLowerCase(), children: a.domain })] }) }), _jsx("div", { children: _jsx("button", { onClick: () => navigate("manage", {
                                state: a,
                            }), children: _jsx(FormattedMessage, { id: '0Azlrb', defaultMessage: 'Manage' }) }) })] }, a.id))), handles.length > 0 && (_jsx("button", { onClick: () => navigate("/verification"), children: _jsx(FormattedMessage, { id: '5oTnfy', defaultMessage: 'Buy Handle' }) }))] }));
}
