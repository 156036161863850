import { createSlice } from "@reduxjs/toolkit";
const InitState = {
    show: false,
    selectedCustomRelays: false,
};
const ReBroadcastSlice = createSlice({
    name: "ReBroadcast",
    initialState: InitState,
    reducers: {
        setShow: (state, action) => {
            state.show = action.payload;
        },
        setNote: (state, action) => {
            state.note = action.payload;
        },
        setSelectedCustomRelays: (state, action) => {
            state.selectedCustomRelays = action.payload;
        },
        reset: () => InitState,
    },
});
export const { setShow, setNote, setSelectedCustomRelays, reset } = ReBroadcastSlice.actions;
export const reducer = ReBroadcastSlice.reducer;
