import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ApiHost, KieranPubKey, SnortPubKey } from "Const";
import ProfilePreview from "Element/ProfilePreview";
import ZapButton from "Element/ZapButton";
import { bech32ToHex } from "SnortUtils";
import SnortApi from "SnortApi";
const Developers = [
    bech32ToHex(KieranPubKey),
    bech32ToHex("npub107jk7htfv243u0x5ynn43scq9wrxtaasmrwwa8lfu2ydwag6cx2quqncxg"),
    bech32ToHex("npub1r0rs5q2gk0e3dk3nlc7gnu378ec6cnlenqp8a3cjhyzu6f8k5sgs4sq9ac"), // Karnage
];
const Contributors = [
    bech32ToHex("npub10djxr5pvdu97rjkde7tgcsjxzpdzmdguwacfjwlchvj7t88dl7nsdl54nf"),
    bech32ToHex("npub148jmlutaa49y5wl5mcll003ftj59v79vf7wuv3apcwpf75hx22vs7kk9ay"),
    bech32ToHex("npub1xdtducdnjerex88gkg2qk2atsdlqsyxqaag4h05jmcpyspqt30wscmntxy"),
    bech32ToHex("npub1vp8fdcyejd4pqjyrjk9sgz68vuhq7pyvnzk8j0ehlljvwgp8n6eqsrnpsw"),
    bech32ToHex("npub179rec9sw2a5ngkr2wsjpjhwp2ksygjxn6uw5py9daj2ezhw3aw5swv3s6q"),
    bech32ToHex("npub17q5n2z8naw0xl6vu9lvt560lg33pdpe29k0k09umlfxm3vc4tqrq466f2y"),
    bech32ToHex("npub1ltx67888tz7lqnxlrg06x234vjnq349tcfyp52r0lstclp548mcqnuz40t"), // Vivek
];
const Translators = [
    bech32ToHex("npub1s8zws5frm94esxnp9v6zf7vk60m3hum3305n78sr73t78kleus7q8zpwna"),
    bech32ToHex("npub1z0ykz6lp3y8rjjntenns0ee02062g2f0n55u49w44xdemw35vcpsda5jhh"),
    bech32ToHex("npub13wa880se2h3l54k7x76edrkrt4p94sh4q090974mt0z6n09qtntqxp47uk"),
    bech32ToHex("npub147ccm75um0zkn0lr9fg9wrag2g6yxfw234fpmhdwuvaqjyegrhgs46t2td"),
    bech32ToHex("npub1ppxgsqdv4ygvdnzznudahtwqc3vaqjz3824vawfgwchpegz0lsjqqys35r"),
    bech32ToHex("npub1ww8kjxz2akn82qptdpl7glywnchhkx3x04hez3d3rye397turrhssenvtp"),
    bech32ToHex("npub1x8dzy9xegwmdk2vy30l8u08caspcqq2yzncxehdsa6kvnte9pr3qnt8pg4"),
    bech32ToHex("npub1xwm9svxrlymymph0hka40zw9frg98m6adxmzcq26jhtm5gwlhjrshhgzfd"),
    bech32ToHex("npub1raspu6ag9kfcw9jz0rz4z693qwmqe5sx6jdhhuvkwz5zy8rygztqnwfhd7"),
    bech32ToHex("npub13tkge7eqeem5cz8gk7gdju76nytvvf064hm5mzmv3x26k2uvaxfqczet2j"),
    bech32ToHex("npub10529hxckjm5t5mchss5lnpsqrmavulglxhrmu5quuu4hs6yuyh3qc9gxd5"),
    bech32ToHex("npub19jk45jz45gczwfm22y9z69xhaex3nwg47dz84zw096xl6z62amkqj99rv7"),
    bech32ToHex("npub1z9n5ktfjrlpyywds9t7ljekr9cm9jjnzs27h702te5fy8p2c4dgs5zvycf"), // Felix - DE
];
export const DonateLNURL = "donate@snort.social";
const DonatePage = () => {
    const [splits, setSplits] = useState([]);
    const [today, setSumToday] = useState();
    const api = new SnortApi(ApiHost);
    async function loadData() {
        const rsp = await api.revenueSplits();
        setSplits(rsp);
        const rsp2 = await api.revenueToday();
        setSumToday(rsp2);
    }
    useEffect(() => {
        loadData().catch(console.warn);
    }, []);
    function actions(pk) {
        const split = splits.find(a => bech32ToHex(a.pubKey) === pk);
        if (split) {
            return _jsxs(_Fragment, { children: [(100 * split.split).toLocaleString(), "%"] });
        }
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: "main-content m5", children: [_jsx("h2", { children: _jsx(FormattedMessage, { id: 'Y31HTH', defaultMessage: 'Help fund the development of Snort' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'BOr9z/', defaultMessage: 'Snort is an open source project built by passionate people in their free time' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'nn1qb3', defaultMessage: 'Your donations are greatly appreciated' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'u4bHcR', defaultMessage: 'Check out the code here: {link}', values: {
                        link: (_jsx("a", { className: "highlight", href: "https://git.v0l.io/Kieran/snort", rel: "noreferrer", target: "_blank", children: "https://git.v0l.io/Kieran/snort" })),
                    } }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'mH91FY', defaultMessage: 'Each contributor will get paid a percentage of all donations and NIP-05 orders, you can see the split amounts below' }) }), _jsxs("div", { className: "card", children: [_jsxs("div", { className: "flex", children: [_jsx("div", { className: "mr10", children: _jsx(FormattedMessage, { id: 'ZUZedV', defaultMessage: 'Lightning Donation:' }) }), _jsx(ZapButton, { pubkey: bech32ToHex(SnortPubKey), lnurl: DonateLNURL, children: _jsx(FormattedMessage, { id: '2IFGap', defaultMessage: 'Donate' }) })] }), today && (_jsx("small", { children: _jsx(FormattedMessage, { id: 'P7nJT9', defaultMessage: 'Total today (UTC): {amount} sats', values: { amount: today.donations.toLocaleString() } }) }))] }), _jsx("h3", { children: _jsx(FormattedMessage, { id: '4IPzdn', defaultMessage: 'Primary Developers' }) }), Developers.map(a => (_jsx(ProfilePreview, { pubkey: a, actions: actions(a) }, a))), _jsx("h4", { children: _jsx(FormattedMessage, { id: 'ZLmyG9', defaultMessage: 'Contributors' }) }), Contributors.map(a => (_jsx(ProfilePreview, { pubkey: a, actions: actions(a) }, a))), _jsx("h4", { children: _jsx(FormattedMessage, { id: '3gOsZq', defaultMessage: 'Translators' }) }), Translators.map(a => (_jsx(ProfilePreview, { pubkey: a, actions: actions(a) }, a)))] }));
};
export default DonatePage;
