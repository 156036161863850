export const DefaultPreferences = {
    language: "en",
    enableReactions: true,
    reactionEmoji: "+",
    autoLoadMedia: "follows-only",
    theme: "system",
    confirmReposts: false,
    showDebugMenus: false,
    autoShowLatest: false,
    fileUploader: "void.cat",
    imgProxyConfig: null,
    defaultRootTab: "notes",
    defaultZapAmount: 50,
    autoZap: false,
};
