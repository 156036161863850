var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _NostrConnectWallet_instances, _NostrConnectWallet_config, _NostrConnectWallet_conn, _NostrConnectWallet_commandQueue, _NostrConnectWallet_onReply, _NostrConnectWallet_rpc;
import { Connection, EventKind, EventBuilder, EventExt } from "@snort/system";
import { WalletError, WalletErrorCode, WalletInvoiceState } from "Wallet";
import debug from "debug";
export class NostrConnectWallet {
    constructor(cfg) {
        _NostrConnectWallet_instances.add(this);
        _NostrConnectWallet_config.set(this, void 0);
        _NostrConnectWallet_conn.set(this, void 0);
        _NostrConnectWallet_commandQueue.set(this, void 0);
        __classPrivateFieldSet(this, _NostrConnectWallet_config, NostrConnectWallet.parseConfigUrl(cfg), "f");
        __classPrivateFieldSet(this, _NostrConnectWallet_commandQueue, new Map(), "f");
    }
    static parseConfigUrl(url) {
        const uri = new URL(url.replace("nostrwalletconnect://", "http://").replace("nostr+walletconnect://", "http://"));
        return {
            relayUrl: uri.searchParams.get("relay"),
            walletPubkey: uri.host,
            secret: uri.searchParams.get("secret"),
        };
    }
    canAutoLogin() {
        return true;
    }
    isReady() {
        return __classPrivateFieldGet(this, _NostrConnectWallet_conn, "f") !== undefined;
    }
    async getInfo() {
        await this.login();
        return await new Promise((resolve, reject) => {
            __classPrivateFieldGet(this, _NostrConnectWallet_commandQueue, "f").set("info", {
                resolve: (o) => {
                    resolve({
                        alias: "NWC",
                        chains: o.split(" "),
                    });
                },
                reject,
            });
            __classPrivateFieldGet(this, _NostrConnectWallet_conn, "f")?.QueueReq(["REQ", "info", { kinds: [13194], limit: 1 }], () => {
                // ignored
            });
        });
    }
    async login() {
        if (__classPrivateFieldGet(this, _NostrConnectWallet_conn, "f"))
            return true;
        return await new Promise(resolve => {
            __classPrivateFieldSet(this, _NostrConnectWallet_conn, new Connection(__classPrivateFieldGet(this, _NostrConnectWallet_config, "f").relayUrl, { read: true, write: true }), "f");
            __classPrivateFieldGet(this, _NostrConnectWallet_conn, "f").OnConnected = () => resolve(true);
            __classPrivateFieldGet(this, _NostrConnectWallet_conn, "f").Auth = async (c, r) => {
                const eb = new EventBuilder();
                eb.kind(EventKind.Auth).tag(["relay", r]).tag(["challenge", c]);
                return await eb.buildAndSign(__classPrivateFieldGet(this, _NostrConnectWallet_config, "f").secret);
            };
            __classPrivateFieldGet(this, _NostrConnectWallet_conn, "f").OnEvent = (s, e) => {
                __classPrivateFieldGet(this, _NostrConnectWallet_instances, "m", _NostrConnectWallet_onReply).call(this, s, e);
            };
            __classPrivateFieldGet(this, _NostrConnectWallet_conn, "f").Connect();
        });
    }
    async close() {
        __classPrivateFieldGet(this, _NostrConnectWallet_conn, "f")?.Close();
        return true;
    }
    async getBalance() {
        return 0;
    }
    createInvoice() {
        return Promise.reject(new WalletError(WalletErrorCode.GeneralError, "Not implemented"));
    }
    async payInvoice(pr) {
        await this.login();
        const rsp = await __classPrivateFieldGet(this, _NostrConnectWallet_instances, "m", _NostrConnectWallet_rpc).call(this, "pay_invoice", {
            invoice: pr,
        });
        if (!rsp.error) {
            return {
                ...rsp.result,
                pr,
                state: WalletInvoiceState.Paid,
            };
        }
        else {
            throw new WalletError(WalletErrorCode.GeneralError, rsp.error.message);
        }
    }
    getInvoices() {
        return Promise.resolve([]);
    }
}
_NostrConnectWallet_config = new WeakMap(), _NostrConnectWallet_conn = new WeakMap(), _NostrConnectWallet_commandQueue = new WeakMap(), _NostrConnectWallet_instances = new WeakSet(), _NostrConnectWallet_onReply = async function _NostrConnectWallet_onReply(sub, e) {
    if (sub === "info") {
        const pending = __classPrivateFieldGet(this, _NostrConnectWallet_commandQueue, "f").get("info");
        if (!pending) {
            throw new WalletError(WalletErrorCode.GeneralError, "No pending info command found");
        }
        pending.resolve(e.content);
        __classPrivateFieldGet(this, _NostrConnectWallet_commandQueue, "f").delete("info");
        return;
    }
    if (e.kind !== 23195) {
        throw new WalletError(WalletErrorCode.GeneralError, "Unknown event kind");
    }
    const replyTo = e.tags.find(a => a[0] === "e");
    if (!replyTo) {
        throw new WalletError(WalletErrorCode.GeneralError, "Missing e-tag in command response");
    }
    const pending = __classPrivateFieldGet(this, _NostrConnectWallet_commandQueue, "f").get(replyTo[1]);
    if (!pending) {
        throw new WalletError(WalletErrorCode.GeneralError, "No pending command found");
    }
    pending.resolve(e.content);
    __classPrivateFieldGet(this, _NostrConnectWallet_commandQueue, "f").delete(replyTo[1]);
    __classPrivateFieldGet(this, _NostrConnectWallet_conn, "f")?.CloseReq(sub);
}, _NostrConnectWallet_rpc = async function _NostrConnectWallet_rpc(method, params) {
    if (!__classPrivateFieldGet(this, _NostrConnectWallet_conn, "f"))
        throw new WalletError(WalletErrorCode.GeneralError, "Not implemented");
    const payload = JSON.stringify({
        method,
        params,
    });
    const eb = new EventBuilder();
    eb.kind(23194)
        .content(await EventExt.encryptDm(payload, __classPrivateFieldGet(this, _NostrConnectWallet_config, "f").secret, __classPrivateFieldGet(this, _NostrConnectWallet_config, "f").walletPubkey))
        .tag(["p", __classPrivateFieldGet(this, _NostrConnectWallet_config, "f").walletPubkey]);
    const evCommand = await eb.buildAndSign(__classPrivateFieldGet(this, _NostrConnectWallet_config, "f").secret);
    __classPrivateFieldGet(this, _NostrConnectWallet_conn, "f").QueueReq([
        "REQ",
        evCommand.id.slice(0, 12),
        {
            kinds: [23195],
            authors: [__classPrivateFieldGet(this, _NostrConnectWallet_config, "f").walletPubkey],
            ["#e"]: [evCommand.id],
        },
    ], () => {
        // ignored
    });
    await __classPrivateFieldGet(this, _NostrConnectWallet_conn, "f").SendAsync(evCommand);
    return await new Promise((resolve, reject) => {
        __classPrivateFieldGet(this, _NostrConnectWallet_commandQueue, "f").set(evCommand.id, {
            resolve: async (o) => {
                const reply = JSON.parse(await EventExt.decryptDm(o, __classPrivateFieldGet(this, _NostrConnectWallet_config, "f").secret, __classPrivateFieldGet(this, _NostrConnectWallet_config, "f").walletPubkey));
                debug("NWC")("%o", reply);
                resolve(reply);
            },
            reject,
        });
    });
};
