import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./NoteToSelf.css";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { profileLink } from "SnortUtils";
import messages from "./messages";
import Icon from "Icons/Icon";
function NoteLabel() {
    return (_jsxs("div", { children: [_jsx(FormattedMessage, { ...messages.NoteToSelf }), " ", _jsx(Icon, { name: "badge", size: 15 })] }));
}
export default function NoteToSelf({ pubkey, clickable, className, link }) {
    const navigate = useNavigate();
    const clickLink = () => {
        if (clickable) {
            navigate(link ?? profileLink(pubkey));
        }
    };
    return (_jsxs("div", { className: `nts${className ? ` ${className}` : ""}`, children: [_jsx("div", { className: "avatar-wrapper", children: _jsx("div", { className: `avatar${clickable ? " clickable" : ""}`, children: _jsx(Icon, { onClick: clickLink, name: "book-closed", size: 20 }) }) }), _jsx("div", { className: "f-grow", children: _jsx("div", { className: "name", children: (clickable && (_jsx(Link, { to: link ?? profileLink(pubkey), children: _jsx(NoteLabel, {}) }))) || _jsx(NoteLabel, {}) }) })] }));
}
