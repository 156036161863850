import { EventKind, FlatNoteStore, RequestBuilder } from "@snort/system";
import { useRequestBuilder } from "@snort/system-react";
import { System } from "index";
import { useMemo } from "react";
export function useLiveChatFeed(link) {
    const sub = useMemo(() => {
        const rb = new RequestBuilder(`live:${link.id}:${link.author}`);
        rb.withOptions({
            leaveOpen: true,
        });
        rb.withFilter()
            .kinds([EventKind.ZapReceipt, 1311])
            .tag("a", [`${link.kind}:${link.author}:${link.id}`])
            .limit(100);
        return rb;
    }, [link]);
    return useRequestBuilder(System, FlatNoteStore, sub);
}
