import { jsx as _jsx } from "react/jsx-runtime";
import "./index.css";
import GetVerified from "Pages/new/GetVerified";
import ProfileSetup from "Pages/new/ProfileSetup";
import NewUserFlow from "Pages/new/NewUserFlow";
import ImportFollows from "Pages/new/ImportFollows";
import DiscoverFollows from "Pages/new/DiscoverFollows";
export const PROFILE = "/new/profile";
export const IMPORT = "/new/import";
export const DISCOVER = "/new/discover";
export const VERIFY = "/new/verify";
export const NewUserRoutes = [
    {
        path: "/new",
        element: _jsx(NewUserFlow, {}),
    },
    {
        path: PROFILE,
        element: _jsx(ProfileSetup, {}),
    },
    {
        path: IMPORT,
        element: _jsx(ImportFollows, {}),
    },
    {
        path: VERIFY,
        element: _jsx(GetVerified, {}),
    },
    {
        path: DISCOVER,
        element: _jsx(DiscoverFollows, {}),
    },
];
