import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ProxyImg } from "Element/ProxyImg";
import { useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import { decodeInvoice } from "@snort/shared";
import "./MediaElement.css";
import Modal from "Element/Modal";
import Icon from "Icons/Icon";
import { kvToObject } from "SnortUtils";
import AsyncButton from "Element/AsyncButton";
import { useWallet } from "Wallet";
import { PaymentsCache } from "Cache";
import PageSpinner from "Element/PageSpinner";
import { LiveVideoPlayer } from "Element/LiveVideoPlayer";
export function MediaElement(props) {
    const [invoice, setInvoice] = useState();
    const [l402, setL402] = useState();
    const [auth, setAuth] = useState();
    const [error, setError] = useState("");
    const [url, setUrl] = useState(props.url);
    const [loading, setLoading] = useState(false);
    const wallet = useWallet();
    async function probeFor402() {
        const cached = await PaymentsCache.get(props.url);
        if (cached) {
            setAuth(cached);
            return;
        }
        const req = new Request(props.url, {
            method: "OPTIONS",
            headers: {
                accept: "L402",
            },
        });
        const rsp = await fetch(req);
        if (rsp.status === 402) {
            const auth = rsp.headers.get("www-authenticate");
            if (auth?.startsWith("L402")) {
                const vals = kvToObject(auth.substring(5));
                console.debug(vals);
                setL402(vals);
                if (vals.invoice) {
                    const decoded = decodeInvoice(vals.invoice);
                    setInvoice(decoded);
                }
            }
        }
    }
    async function payInvoice() {
        if (wallet.wallet && l402) {
            try {
                const res = await wallet.wallet.payInvoice(l402.invoice);
                console.debug(res);
                if (res.preimage) {
                    const pmt = {
                        pr: l402.invoice,
                        url: props.url,
                        macaroon: l402.macaroon,
                        preimage: res.preimage,
                    };
                    await PaymentsCache.set(pmt);
                    setAuth(pmt);
                }
            }
            catch (e) {
                if (e instanceof Error) {
                    setError(e.message);
                }
            }
        }
    }
    async function loadMedia() {
        if (!auth)
            return;
        setLoading(true);
        const mediaReq = new Request(props.url, {
            headers: {
                Authorization: `L402 ${auth.macaroon}:${auth.preimage}`,
            },
        });
        const rsp = await fetch(mediaReq);
        if (rsp.ok) {
            const buf = await rsp.blob();
            setUrl(URL.createObjectURL(buf));
        }
        setLoading(false);
    }
    useEffect(() => {
        if (auth) {
            loadMedia().catch(console.error);
        }
    }, [auth]);
    if (auth && loading) {
        return _jsx(PageSpinner, {});
    }
    if (invoice) {
        return (_jsxs("div", { className: "note-invoice", children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: '9qtLJC', defaultMessage: 'Payment Required' }) }), _jsxs("div", { className: "flex f-row", children: [_jsx("div", { className: "f-grow", children: _jsx(FormattedMessage, { id: 'DqLx9k', defaultMessage: 'You must pay {n} sats to access this file.', values: {
                                    n: _jsx(FormattedNumber, { value: (invoice.amount ?? 0) / 1000 }),
                                } }) }), _jsx("div", { children: wallet.wallet && (_jsx(AsyncButton, { onClick: () => payInvoice(), children: _jsx(FormattedMessage, { id: 'Ss0sWu', defaultMessage: 'Pay Now' }) })) })] }), !wallet.wallet && (_jsx("b", { children: _jsx(FormattedMessage, { id: '0ehN4t', defaultMessage: 'Please connect a wallet {here} to be able to pay this invoice', values: {
                            here: (_jsx(Link, { to: "/settings/wallet", onClick: e => e.stopPropagation(), children: _jsx(FormattedMessage, { id: '380eol', defaultMessage: 'here' }) })),
                        } }) })), error && _jsx("b", { className: "error", children: error })] }));
    }
    if (props.mime.startsWith("image/")) {
        if (!(props.disableSpotlight ?? false)) {
            return (_jsx(SpotlightMedia, { children: _jsx(ProxyImg, { src: url, onError: () => probeFor402() }, props.url) }));
        }
        else {
            return _jsx(ProxyImg, { src: url, onError: () => probeFor402() }, props.url);
        }
    }
    else if (props.mime.startsWith("audio/")) {
        return _jsx("audio", { src: url, controls: true, onError: () => probeFor402() }, props.url);
    }
    else if (props.mime.startsWith("video/")) {
        if (props.url.endsWith(".m3u8")) {
            return _jsx(LiveVideoPlayer, { stream: props.url });
        }
        return _jsx("video", { src: url, controls: true, onError: () => probeFor402() }, props.url);
    }
    else {
        return (_jsx("a", { href: props.url, onClick: e => e.stopPropagation(), target: "_blank", rel: "noreferrer", className: "ext", children: props.url }, props.url));
    }
}
export function SpotlightMedia({ children }) {
    const [showModal, setShowModal] = useState(false);
    function onClick(e) {
        e.stopPropagation();
        e.preventDefault();
        setShowModal(s => !s);
    }
    function onClose(e) {
        e.stopPropagation();
        e.preventDefault();
        setShowModal(false);
    }
    return (_jsxs(_Fragment, { children: [showModal && (_jsxs(Modal, { onClose: onClose, className: "spotlight", children: [_jsx("div", { className: "close", onClick: onClose, children: _jsx(Icon, { name: "close" }) }), children] })), _jsx("div", { onClick: onClick, children: children })] }));
}
