import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./DmWindow.css";
import { useMemo } from "react";
import ProfileImage from "Element/ProfileImage";
import DM from "Element/DM";
import NoteToSelf from "Element/NoteToSelf";
import useLogin from "Hooks/useLogin";
import WriteMessage from "Element/WriteMessage";
import { ChatType, useChatSystem } from "chat";
import { Nip4ChatSystem } from "chat/nip4";
export default function DmWindow({ id }) {
    const pubKey = useLogin().publicKey;
    const dms = useChatSystem();
    const chat = dms.find(a => a.id === id) ?? Nip4ChatSystem.createChatObj(id, []);
    function sender() {
        if (id === pubKey) {
            return _jsx(NoteToSelf, { className: "f-grow mb-10", pubkey: id });
        }
        if (chat?.type === ChatType.DirectMessage) {
            return _jsx(ProfileImage, { pubkey: id, className: "f-grow mb10" });
        }
        if (chat?.profile) {
            return _jsx(ProfileImage, { pubkey: id, className: "f-grow mb10", profile: chat.profile });
        }
        return _jsx(ProfileImage, { pubkey: id ?? "", className: "f-grow mb10", overrideUsername: chat?.id });
    }
    return (_jsxs("div", { className: "dm-window", children: [_jsx("div", { children: sender() }), _jsx("div", { children: _jsx("div", { className: "flex f-col", children: chat && _jsx(DmChatSelected, { chat: chat }) }) }), _jsx("div", { children: _jsx(WriteMessage, { chat: chat }) })] }));
}
function DmChatSelected({ chat }) {
    const { publicKey: myPubKey } = useLogin();
    const sortedDms = useMemo(() => {
        const myDms = chat?.messages;
        if (myPubKey && myDms) {
            // filter dms in this chat, or dms to self
            return [...myDms].sort((a, b) => a.created_at - b.created_at);
        }
        return [];
    }, [chat, myPubKey]);
    return (_jsx(_Fragment, { children: sortedDms.map(a => (_jsx(DM, { data: a, chat: chat }, a.id))) }));
}
