import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NostrPrefix, encodeTLV } from "@snort/system";
import { findTag, unwrap } from "SnortUtils";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
export function LiveEvent({ ev }) {
    const title = findTag(ev, "title");
    const d = unwrap(findTag(ev, "d"));
    return (_jsx("div", { className: "text", children: _jsxs("div", { className: "flex card", children: [_jsx("div", { className: "f-grow", children: _jsx("h3", { children: title }) }), _jsx("div", { children: _jsx(Link, { to: `/live/${encodeTLV(NostrPrefix.Address, d, undefined, ev.kind, ev.pubkey)}`, children: _jsx("button", { className: "primary", type: "button", children: _jsx(FormattedMessage, { id: 'HF4YnO', defaultMessage: 'Watch Live!' }) }) }) })] }) }));
}
