import { jsx as _jsx } from "react/jsx-runtime";
import "./Tabs.css";
import useHorizontalScroll from "Hooks/useHorizontalScroll";
export const TabElement = ({ t, tab, setTab }) => {
    return (_jsx("div", { className: `tab ${tab.value === t.value ? "active" : ""} ${t.disabled ? "disabled" : ""}`, onClick: () => !t.disabled && setTab(t), children: t.text }));
};
const Tabs = ({ tabs, tab, setTab }) => {
    const horizontalScroll = useHorizontalScroll();
    return (_jsx("div", { className: "tabs", ref: horizontalScroll, children: tabs.map(t => (_jsx(TabElement, { tab: tab, setTab: setTab, t: t }, t.value))) }));
};
export default Tabs;
