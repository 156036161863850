import Dexie from "dexie";
export const NAME = "snortDB";
export const VERSION = 11;
const STORES = {
    chats: "++id",
    eventInteraction: "++id",
    payments: "++url",
};
export class SnortDB extends Dexie {
    constructor() {
        super(NAME);
        this.ready = false;
        this.version(VERSION).stores(STORES);
    }
    isAvailable() {
        if ("indexedDB" in window) {
            return new Promise(resolve => {
                const req = window.indexedDB.open("dummy", 1);
                req.onsuccess = () => {
                    resolve(true);
                };
                req.onerror = () => {
                    resolve(false);
                };
            });
        }
        return Promise.resolve(false);
    }
}
export const db = new SnortDB();
