import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import Note from "Element/Note";
import useLogin from "Hooks/useLogin";
import { UserCache } from "Cache";
import messages from "./messages";
const Bookmarks = ({ pubkey, bookmarks, related }) => {
    const [onlyPubkey, setOnlyPubkey] = useState("all");
    const loginPubKey = useLogin().publicKey;
    const ps = useMemo(() => {
        return [...new Set(bookmarks.map(ev => ev.pubkey))];
    }, [bookmarks]);
    function renderOption(p) {
        const profile = UserCache.getFromCache(p);
        return profile ? _jsx("option", { value: p, children: profile?.display_name || profile?.name }) : null;
    }
    return (_jsxs("div", { className: "main-content", children: [_jsx("div", { className: "mb10 flex-end", children: _jsxs("select", { disabled: ps.length <= 1, value: onlyPubkey, onChange: (e) => setOnlyPubkey(e.target.value), children: [_jsx("option", { value: "all", children: _jsx(FormattedMessage, { ...messages.All }) }), ps.map(renderOption)] }) }), bookmarks
                .filter(b => (onlyPubkey === "all" ? true : b.pubkey === onlyPubkey))
                .map(n => {
                return (_jsx(Note, { data: n, related: related, options: { showTime: false, showBookmarked: true, canUnbookmark: loginPubKey === pubkey } }, n.id));
            })] }));
};
export default Bookmarks;
