import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import Logo from "Element/Logo";
import { CollapsedSection } from "Element/Collapsed";
import Copy from "Element/Copy";
import { hexToBech32 } from "SnortUtils";
import { hexToMnemonic } from "nip6";
import useLogin from "Hooks/useLogin";
import { PROFILE } from ".";
import { DefaultPreferences, updatePreferences } from "Login";
import { AllLanguageCodes } from "Pages/settings/Preferences";
import messages from "./messages";
const WhatIsSnort = () => {
    return (_jsxs(CollapsedSection, { title: _jsx("h3", { children: _jsx(FormattedMessage, { ...messages.WhatIsSnort }) }), children: [_jsx("p", { children: _jsx(FormattedMessage, { ...messages.WhatIsSnortIntro }) }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.WhatIsSnortNotes }) }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.WhatIsSnortExperience }) })] }));
};
const HowDoKeysWork = () => {
    return (_jsxs(CollapsedSection, { title: _jsx("h3", { children: _jsx(FormattedMessage, { ...messages.HowKeysWork }) }), children: [_jsx("p", { children: _jsx(FormattedMessage, { ...messages.DigitalSignatures }) }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.TamperProof }) }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.Bitcoin }) })] }));
};
const Extensions = () => {
    return (_jsxs(CollapsedSection, { title: _jsx("h3", { children: _jsx(FormattedMessage, { ...messages.ImproveSecurity }) }), children: [_jsx("p", { children: _jsx(FormattedMessage, { ...messages.Extensions }) }), _jsxs("ul", { children: [_jsx("li", { children: _jsx("a", { href: "https://getalby.com/", target: "_blank", rel: "noreferrer", children: "Alby" }) }), _jsx("li", { children: _jsx("a", { href: "https://github.com/fiatjaf/nos2x", target: "_blank", rel: "noreferrer", children: "nos2x" }) })] }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.ExtensionsNostr }) })] }));
};
export default function NewUserFlow() {
    const login = useLogin();
    const navigate = useNavigate();
    return (_jsxs("div", { className: "main-content new-user", dir: "auto", children: [_jsx(Logo, {}), _jsx("div", { className: "progress-bar", children: _jsx("div", { className: "progress progress-first" }) }), _jsx("h1", { children: _jsx(FormattedMessage, { ...messages.SaveKeys }) }), _jsxs("div", { className: "card flex", children: [_jsx("div", { className: "flex f-col f-grow", children: _jsx("div", { children: _jsx(FormattedMessage, { id: 'y1Z3or', defaultMessage: 'Language' }) }) }), _jsx("div", { children: _jsx("select", { value: login.preferences.language || DefaultPreferences.language, onChange: e => updatePreferences(login, {
                                ...login.preferences,
                                language: e.target.value,
                            }), style: { textTransform: "capitalize" }, children: AllLanguageCodes.sort().map(a => (_jsx("option", { value: a, children: new Intl.DisplayNames([a], {
                                    type: "language",
                                }).of(a) }))) }) })] }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.SaveKeysHelp }) }), _jsx("h2", { children: _jsx(FormattedMessage, { ...messages.YourPubkey }) }), _jsx(Copy, { text: hexToBech32("npub", login.publicKey ?? "") }), _jsx("h2", { children: _jsx(FormattedMessage, { ...messages.YourMnemonic }) }), _jsx(Copy, { text: hexToMnemonic(login.generatedEntropy ?? "") }), _jsx("div", { className: "next-actions", children: _jsxs("button", { type: "button", onClick: () => navigate(PROFILE), children: [_jsx(FormattedMessage, { ...messages.KeysSaved }), " "] }) }), _jsx(WhatIsSnort, {}), _jsx(HowDoKeysWork, {}), _jsx(Extensions, {})] }));
}
