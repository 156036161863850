import { createSlice } from "@reduxjs/toolkit";
const InitState = {
    show: false,
    note: "",
    error: "",
    active: false,
    showAdvanced: false,
    selectedCustomRelays: false,
    zapForward: "",
    sensitive: "",
    otherEvents: [],
};
const NoteCreatorSlice = createSlice({
    name: "NoteCreator",
    initialState: InitState,
    reducers: {
        setShow: (state, action) => {
            state.show = action.payload;
        },
        setNote: (state, action) => {
            state.note = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        setPreview: (state, action) => {
            state.preview = action.payload;
        },
        setReplyTo: (state, action) => {
            state.replyTo = action.payload;
        },
        setShowAdvanced: (state, action) => {
            state.showAdvanced = action.payload;
        },
        setSelectedCustomRelays: (state, action) => {
            state.selectedCustomRelays = action.payload;
        },
        setZapForward: (state, action) => {
            state.zapForward = action.payload;
        },
        setSensitive: (state, action) => {
            state.sensitive = action.payload;
        },
        setPollOptions: (state, action) => {
            state.pollOptions = action.payload;
        },
        setOtherEvents: (state, action) => {
            state.otherEvents = action.payload;
        },
        reset: () => InitState,
    },
});
export const { setShow, setNote, setError, setActive, setPreview, setReplyTo, setShowAdvanced, setSelectedCustomRelays, setZapForward, setSensitive, setPollOptions, setOtherEvents, reset, } = NoteCreatorSlice.actions;
export const reducer = NoteCreatorSlice.reducer;
