import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./BackButton.css";
import { useIntl } from "react-intl";
import Icon from "Icons/Icon";
import messages from "./messages";
const BackButton = ({ text, onClick }) => {
    const { formatMessage } = useIntl();
    const onClickHandler = () => {
        if (onClick) {
            onClick();
        }
    };
    return (_jsxs("button", { className: "back-button", type: "button", onClick: onClickHandler, children: [_jsx(Icon, { name: "arrowBack" }), text || formatMessage(messages.Back)] }));
};
export default BackButton;
