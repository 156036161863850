import * as utils from "@noble/curves/abstract/utils";
import * as bip39 from "@scure/bip39";
import { wordlist } from "@scure/bip39/wordlists/english";
import { HDKey } from "@scure/bip32";
import { DerivationPath } from "Const";
export function generateBip39Entropy(mnemonic) {
    try {
        const mn = mnemonic ?? bip39.generateMnemonic(wordlist, 256);
        return bip39.mnemonicToEntropy(mn, wordlist);
    }
    catch (e) {
        throw new Error("INVALID MNEMONIC PHRASE");
    }
}
/**
 * Convert hex-encoded entropy into mnemonic phrase
 */
export function hexToMnemonic(hex) {
    const bytes = utils.hexToBytes(hex);
    return bip39.entropyToMnemonic(bytes, wordlist);
}
/**
 * Derrive NIP-06 private key from master key
 */
export function entropyToPrivateKey(entropy) {
    const masterKey = HDKey.fromMasterSeed(entropy);
    const newKey = masterKey.derive(DerivationPath);
    if (!newKey.privateKey) {
        throw new Error("INVALID KEY DERIVATION");
    }
    return utils.bytesToHex(newKey.privateKey);
}
