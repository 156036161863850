import { jsx as _jsx } from "react/jsx-runtime";
import useEventFeed from "Feed/EventFeed";
import Note from "Element/Note";
import PageSpinner from "Element/PageSpinner";
export default function NoteQuote({ link, depth }) {
    const ev = useEventFeed(link);
    if (!ev.data)
        return _jsx(PageSpinner, {});
    return (_jsx(Note, { data: ev.data, related: [], className: "note-quote", depth: (depth ?? 0) + 1, options: {
            showFooter: false,
        } }));
}
