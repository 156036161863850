import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Navigate, useLocation } from "react-router-dom";
import LNForwardAddress from "./LNAddress";
import TransferHandle from "./TransferHandle";
export default function ManageHandleIndex() {
    const location = useLocation();
    const handle = location.state;
    if (!handle) {
        return _jsx(Navigate, { to: "/settings/handle" });
    }
    return (_jsxs(_Fragment, { children: [_jsxs("h3", { className: "nip05", children: [handle.handle, "@", _jsx("span", { className: "domain", "data-domain": handle.domain?.toLowerCase(), children: handle.domain })] }), _jsx(LNForwardAddress, { handle: handle }), _jsx(TransferHandle, { handle: handle })] }));
}
