export default async function NostrImg(file) {
    const fd = new FormData();
    fd.append("image", file);
    const rsp = await fetch("https://nostrimg.com/api/upload", {
        body: fd,
        method: "POST",
        headers: {
            accept: "application/json",
        },
    });
    if (rsp.ok) {
        const data = await rsp.json();
        if (typeof data?.imageUrl === "string" && data.success) {
            return {
                url: new URL(data.imageUrl).toString(),
            };
        }
    }
    return {
        error: "Upload failed",
    };
}
