import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage, FormattedDate, FormattedNumber, useIntl } from "react-intl";
import { useState } from "react";
import SnortApi, { SubscriptionError } from "SnortApi";
import { mapPlanName, mapSubscriptionErrorCode } from ".";
import AsyncButton from "Element/AsyncButton";
import Icon from "Icons/Icon";
import useEventPublisher from "Feed/EventPublisher";
import SendSats from "Element/SendSats";
import Nip5Service from "Element/Nip5Service";
import { SnortNostrAddressService } from "Pages/Verification";
import Nip05 from "Element/Nip05";
export default function SubscriptionCard({ sub }) {
    const publisher = useEventPublisher();
    const { formatMessage } = useIntl();
    const created = new Date(sub.created * 1000);
    const expires = new Date(sub.expires * 1000);
    const now = new Date();
    const daysToExpire = Math.floor((expires.getTime() - now.getTime()) / 8.64e7);
    const hoursToExpire = Math.floor((expires.getTime() - now.getTime()) / 3.6e6);
    const isExpired = sub.state === "expired";
    const isNew = sub.state === "new";
    const isPaid = sub.state === "paid";
    const [invoice, setInvoice] = useState("");
    const [error, setError] = useState();
    async function renew(id) {
        const api = new SnortApi(undefined, publisher);
        try {
            const rsp = await api.renewSubscription(id);
            setInvoice(rsp.pr);
        }
        catch (e) {
            if (e instanceof SubscriptionError) {
                setError(e);
            }
        }
    }
    function subFeatures() {
        return (_jsxs(_Fragment, { children: [!sub.handle && (_jsxs(_Fragment, { children: [_jsx("h3", { children: _jsx(FormattedMessage, { id: 'GUlSVG', defaultMessage: 'Claim your included Snort nostr address' }) }), _jsx(Nip5Service, { ...SnortNostrAddressService, helpText: false, forSubscription: sub.id, onSuccess: h => (sub.handle = h) })] })), sub.handle && _jsx(Nip05, { nip05: sub.handle, pubkey: "", verifyNip: false })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "card", children: [_jsxs("div", { className: "flex card-title", children: [_jsx(Icon, { name: "badge", className: "mr5", size: 25 }), mapPlanName(sub.type)] }), _jsxs("div", { className: "flex", children: [_jsxs("p", { className: "f-1", children: [_jsx(FormattedMessage, { id: 'ORGv1Q', defaultMessage: 'Created' }), ":\u00A0", _jsx("time", { dateTime: created.toISOString(), children: _jsx(FormattedDate, { value: created, dateStyle: "full" }) })] }), daysToExpire >= 1 && (_jsxs("p", { className: "f-1", children: [_jsx(FormattedMessage, { id: 'xhQMeQ', defaultMessage: 'Expires' }), ":\u00A0", _jsx("time", { dateTime: expires.toISOString(), children: _jsx(FormattedMessage, { id: 'rmdsT4', defaultMessage: '{n} days', values: {
                                                n: _jsx(FormattedNumber, { value: daysToExpire, maximumFractionDigits: 0 }),
                                            } }) })] })), daysToExpire >= 0 && daysToExpire < 1 && (_jsxs("p", { className: "f-1", children: [_jsx(FormattedMessage, { id: 'xhQMeQ', defaultMessage: 'Expires' }), ":\u00A0", _jsx("time", { dateTime: expires.toISOString(), children: _jsx(FormattedMessage, { id: '2ukA4d', defaultMessage: '{n} hours', values: {
                                                n: _jsx(FormattedNumber, { value: hoursToExpire, maximumFractionDigits: 0 }),
                                            } }) })] })), isExpired && (_jsx("p", { className: "f-1 error", children: _jsx(FormattedMessage, { id: 'RahCRH', defaultMessage: 'Expired' }) })), isNew && (_jsx("p", { className: "f-1", children: _jsx(FormattedMessage, { id: '6uMqL1', defaultMessage: 'Unpaid' }) }))] }), (isExpired || isNew) && (_jsx("div", { className: "flex", children: _jsx(AsyncButton, { onClick: () => renew(sub.id), children: isExpired ? _jsx(FormattedMessage, { id: 'nWQFic', defaultMessage: 'Renew' }) : _jsx(FormattedMessage, { id: 'Ss0sWu', defaultMessage: 'Pay Now' }) }) })), isPaid && subFeatures()] }), _jsx(SendSats, { invoice: invoice, show: invoice !== "", onClose: () => setInvoice(""), title: formatMessage({ id: "Mrpkot", defaultMessage: "Pay for subscription" }) }), error && _jsx("b", { className: "error", children: mapSubscriptionErrorCode(error) })] }));
}
